import Dimens from 'assets/dimens';
import { Box, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthenticationIllistration from '../registration/components/AuthenticationIllistration';
import { DefaultButton, DefaultInput } from 'common/presentation/components';
import { ArrowBack } from '@mui/icons-material';
import { validateEmail } from 'utils/validation';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { taxId, validateNif } from 'utils/tax-id.helpers';

const DistributerForm = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState({
		companyName: '',
		taxId: '',
		email: '',
		certificationCode: '',
		headquarters: '',
		website: '',
	});
	const [formErrors, setFormErrors] = useState({
		companyName: '',
		taxId: '',
		email: '',
		certificationCode: '',
		headquarters: '',
		website: '',
	});

	const handleGoBack = () => {
		navigate(AppLinks.STORE_AREA_REGISTRATION);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormValues({ ...formValues, [event.target.name]: event.target.value });
		setFormErrors({ ...formErrors, [event.target.name]: '' });
	};

	const handleSubmit = () => {
		const errors = {
			companyName: '',
			taxId: '',
			email: '',
			certificationCode: '',
			headquarters: '',
			website: '',
		};
		if (!formValues.companyName) {
			errors.companyName = t('required');
		}
		if (!formValues.email) {
			errors.email = t('required');
		}
		if (formValues.email && !validateEmail(formValues.email)) {
			errors.email = t('email_error');
		}
		if (!formValues.taxId) {
			errors.taxId = t('required');
		}
		if (formValues.taxId && !validateNif(formValues.taxId)) {
			errors.taxId = t('nif_error');
		}
		if (errors.companyName || errors.email || errors.taxId) {
			setFormErrors(errors);
		}
	};

	return (
		<Box
			sx={{
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<DefaultButton
				endIcon={null}
				startIcon={<ArrowBack />}
				onClick={handleGoBack}
			>
				{t('go_back_to_account')}
			</DefaultButton>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				justifyContent="space-between"
				alignItems={{ xs: 'center', md: 'flex-start' }}
				sx={{ mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE } }}
			>
				<AuthenticationIllistration
					titleText={t('become_a_partner').toUpperCase()}
					titleLink=""
					height={Dimens.ILLUSTRATION_WIDTH}
					width={Dimens.ILLUSTRATION_WIDTH}
					titleFontSize={Dimens.BODY_TEXT_XL}
				/>
				<Box sx={{ width: { md: '65%', xs: '100%' }, mt: { md: 0, xs: 2 } }}>
					<Grid container spacing={4} rowSpacing={4}>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="companyName"
								label={`${t('comapany_name')}?`}
								placeholder={t('comapany_name')}
								value={formValues.companyName}
								error={!!formErrors.companyName}
								helperText={formErrors.companyName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="taxId"
								label={t('introduce_company_tax_id')}
								placeholder={t('tax_number')}
								value={formValues.taxId}
								error={!!formErrors.taxId}
								helperText={formErrors.taxId}
								onChange={(e) => handleChange(taxId(e))}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="email"
								label={t('what_is_your_email')}
								placeholder="email@youremail.com"
								value={formValues.email}
								error={!!formErrors.email}
								helperText={formErrors.email}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="certificationCode"
								label={t('permanant_certification_code')}
								placeholder={t('parmanant_certification_code')}
								value={formValues.certificationCode}
								error={!!formErrors.certificationCode}
								helperText={formErrors.certificationCode}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="headquarters"
								label={t('company_headquarters')}
								placeholder={t('address')}
								value={formValues.headquarters}
								error={!!formErrors.headquarters}
								helperText={formErrors.headquarters}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<DefaultInput
								name="website"
								label={t('website')}
								placeholder="www.website.com"
								value={formValues.website}
								error={!!formErrors.website}
								helperText={formErrors.website}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Stack alignItems="flex-end">
						<DefaultButton
							sx={{
								mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
							}}
							onClick={handleSubmit}
						>
							{t('submit_request')}
						</DefaultButton>
					</Stack>
				</Box>
			</Stack>
		</Box>
	);
};

export default DistributerForm;
