/* eslint-disable space-before-blocks */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { AxiosError } from 'axios';
import { IStoreProduct } from '../../domain/entities/product';
import { IProductStoreRepository } from '../../domain/repository/product-repository';
import { ProductStoreApiImpl } from '../remote/product-api-impl';

export class ProductStoreRepositoryImpl implements IProductStoreRepository {
	productStoreApi = new ProductStoreApiImpl();

	async getAllProducts(categoryId?: string): Promise<Either<AxiosError, IStoreProduct[]>>{
		try {
			const productRes = await this.productStoreApi.getAllProducts(categoryId);
			return new Right(productRes);
		} catch (error) {
			throw new Left(error);
		}
	}

	async getProductById(id: string): Promise<Either<AxiosError, IStoreProduct>>{
		try {
			const prdIdRes = await this.productStoreApi.getProductById(id);
			return new Right(prdIdRes);
		} catch (error) {
			throw new Left(error);
		}
	}
}
