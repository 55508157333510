/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { ICommunityRequest } from '../../domain/entities/community-request';
import { CommunityRequestRepositoryImpl } from '../../data/repository/community-request-repository-impl';
import { GetCommunityRequests } from '../../domain/usecases/get-community-requests';

type TProps = {
	communityRequests: ICommunityRequest[]
	loading: boolean
	getCommunityRequests: () => void
}

const BackofficeCommunityReqContext = createContext({} as TProps);

export const CommunityBackofficeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [communityRequests, setCommunityRequests] = useState([] as ICommunityRequest[]);
	const [loading, setLoading] = useState(false);

	const communityReqRepository = new CommunityRequestRepositoryImpl();

	const getCommunityRequests = async () => {
		const communityReqUsecase = new GetCommunityRequests(communityReqRepository);
		const communityReqResult = await communityReqUsecase.call();
		if (communityReqResult.isRight()) {
			setLoading(false);
			setCommunityRequests(communityReqResult.value);
		} else {
			setLoading(false);
		}
	};

	const value = useMemo(() => ({
		communityRequests,
		loading,
		getCommunityRequests,
	}), [communityRequests, loading]);

	return (
		<BackofficeCommunityReqContext.Provider value={value}>
			{children}
		</BackofficeCommunityReqContext.Provider>
	);
};

export function useBackofficeCommunityRequest() {
	return useContext(BackofficeCommunityReqContext);
}
