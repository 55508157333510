import Dimens from 'assets/dimens';
import {
	Box, Checkbox, Divider, FormControlLabel, FormGroup, FormHelperText, Stack, useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	BodyText, DefaultButton, DefaultInput, SizedBox,
} from 'common/presentation/components';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import useOrder from '../provider/order-provider';
import { IContactDetails } from '../../domain/entities/contact-detail';
import useCart from 'common/presentation/providers/cart.provider';
import { formatPrice } from 'utils/price.utils';

interface Props {
	finalizeOrder: (data: IContactDetails) => void
}

const FinalizeOrderForm: FC<Props> = (props) => {
	const { finalizeOrder } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const { loading, error } = useOrder();
	const { cartProducts, getTotalPrice } = useCart();

	const [formData, setFormData] = React.useState({
		fullName: '',
		email: '',
		phone: '',
		privacyPolicy: false,
	});

	const [formError, setFormError] = React.useState({
		fullName: '',
		email: '',
		phone: '',
		privacyPolicy: '',
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

	const handleFormSubmit = (): null => {
		const mError = {
			fullName: '',
			email: '',
			phone: '',
			privacyPolicy: '',
		};
		if (!formData.fullName) {
			mError.fullName = t('please_enter_your_name');
		}
		if (!formData.email) {
			mError.email = t('please_enter_your_email');
		}
		if (!formData.phone) {
			mError.phone = t('please_enter_your_phone');
		}
		if (!formData.privacyPolicy) {
			mError.privacyPolicy = t('please_acknowledge_privacy_policy');
		}
		setFormError(mError);
		if (mError.fullName || mError.email || mError.phone || mError.privacyPolicy) {
			return null;
		}
		setFormError({
			fullName: '',
			email: '',
			phone: '',
			privacyPolicy: '',
		});
		if (cartProducts.length === 0) { return null; }
		finalizeOrder({
			fullName: formData.fullName,
			email: formData.email,
			phone: formData.phone,
		});
		return null;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				border: 1.5,
				borderTopLeftRadius: Dimens.CARD_BORDER_RADIUS,
				borderBottomRightRadius: Dimens.CARD_BORDER_RADIUS,
				borderColor: theme.palette.primary.main,
				p: {
					xs: Dimens.XS_SMALL_PADDING_PAIR,
					sm: Dimens.SM_SMALL_PADDING_PAIR,
					md: Dimens.MD_MEDIUM_PADDING_PAIR,
				},
				flexGrow: 1,
				width: Dimens.CHECKOUT_CARD_WIDTH,
				maxWidth: Dimens.COMMUNITY_IMAGE_HEIGHT,
			}}
		>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				mt: 1,
				fontStyle: 'italic',
			}}
			>
				{t('insert_your_data').toUpperCase()}
			</BodyText>
			<Box sx={{ mt: { md: Dimens.CLIENT_AREA_PADDING_BOTTOM, xs: Dimens.CLIENT_AREA_PADDING_BOTTOM } }}>
				<DefaultInput
					id="fullName"
					value={formData.fullName}
					error={!!formError.fullName}
					helperText={formError.fullName}
					onChange={handleChange}
					label={t('what_is_your_name')}
					placeholder={t('your_full_name')}
				/>
				<SizedBox height={3} />
				<DefaultInput
					id="email"
					value={formData.email}
					error={!!formError.email}
					helperText={formError.email}
					onChange={handleChange}
					label={t('what_is_your_email')}
					placeholder={t('your_email')}
				/>
				<SizedBox height={3} />
				<DefaultInput
					id="phone"
					value={formData.phone}
					error={!!formError.phone}
					helperText={formError.phone}
					onChange={handleChange}
					label={t('what_is_your_phone')}
					placeholder={t('your_phone')}
				/>
				<SizedBox height={1} />
				<BodyText sx={{ fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE } }}>{t('phone_format_info')}</BodyText>
			</Box>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_MS,
				fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				mt: 2,
				fontStyle: 'italic',
			}}
			>
				{t('keep_me_informed').toUpperCase()}
			</BodyText>
			<Stack sx={{ mt: 2 }} direction={{ md: 'row', xs: 'column' }} justifyContent="space-between">
				<FormGroup>
					<FormControlLabel
						control={(
							<Checkbox
								value={formData.privacyPolicy}
								onChange={() => setFormData((prevState) => ({
									...prevState,
									privacyPolicy: !prevState.privacyPolicy,
								}))}
								icon={<RadioButtonUnchecked color="primary" />}
								checkedIcon={<RadioButtonChecked />}
							/>
						)}
						sx={{ color: theme.palette.textMain.main }}
						label={(
							<BodyText>
								{t('acknowledge_privacy_policy')}
								<a
									style={{
										textDecoration: 'none',
										color: theme.palette.textMain.main,
										fontWeight: 'bold',
										cursor: 'pointer',
									}}
									href="https://ajulio.pt/politica_privacidade.php"
									rel="noreferrer"
									target="_blank"
								>
									{t('iokee_cookie_policy')}
								</a>
							</BodyText>
						)}
					/>
					<FormHelperText error={!!formError.privacyPolicy}>
						{formError.privacyPolicy}
					</FormHelperText>
				</FormGroup>
			</Stack>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_BOLDER,
				mt: 2,
			}}
			>
				{t('summary_of_your_purchase')}
				:
			</BodyText>
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_S,
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('subtotal')}
				</BodyText>
				<Box flexGrow={1} />
				<BodyText pt={2} sx={{ fontSize: Dimens.BODY_TEXT_S }}>
					{formatPrice(getTotalPrice())}
				</BodyText>

			</Stack>
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_S,
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('delivery')}
				</BodyText>
				<Box flexGrow={1} />
				<BodyText pt={2} sx={{ fontSize: Dimens.BODY_TEXT_S }}>
					{t('free')}
				</BodyText>
			</Stack>
			<Divider sx={{ mt: 1, mb: 1 }} />
			<Stack direction="row" spacing={2} alignItems="center">
				<BodyText sx={{
					fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					{t('total')}
				</BodyText>
				<Box flexGrow={1} />
				<Box>
					<BodyText
						pt={2}
						sx={{
							fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
						}}
					>
						{formatPrice(getTotalPrice())}
					</BodyText>
					<BodyText
						sx={{ fontSize: Dimens.BUTTON_TEXT_MOBILE, color: theme.palette.grey[500] }}
					>
						{t('taxes_included')}
					</BodyText>
				</Box>
			</Stack>
			<DefaultButton
				loading={loading}
				variant="contained"
				onClick={handleFormSubmit}
				sx={{ mt: 2 }}
			>
				{t('finalize_request')}
			</DefaultButton>
			<BodyText color="error">{error}</BodyText>
		</Box>
	);
};

export default FinalizeOrderForm;
