export const EmptyProduct = {
	name: '',
	description: '',
	price: '0',
	storageNote: '',
	howItWorks: '',
	categoryId: 0,
	warrantyMonths: '0',
	isHighlighted: true,
	attachments: [
		{
			attachmentId: '',
			isMain: true,
			url: '',
		},
	],
	installationSteps: [
		{
			header: '',
			description: '',
			stepOrder: 1,
			attachmentId: '',
		},
	],
	specifications: [
		{
			header: '',
			description: '',
			attachmentId: '',
		},
	],
};
