import { Box } from '@mui/material';
import React, { FC } from 'react';
import ListItem from './ListItem';
import { ITariffDataset } from '../../domain/entities/tariff-dataset';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IDevice } from '@/features/client-device-list/entities/device';
import { useTranslation } from 'react-i18next';

interface TariffPlanItemProps {
	tariffDataSet: ITariffDataset
}

const TarifPlanItem: FC<TariffPlanItemProps> = (props) => {
	const { tariffDataSet } = props;
	const { t } = useTranslation('translations');
	const device = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;

	return (
		<Box sx={{ mt: 5 }}>
			<ListItem title={t('invoice_start')} value={tariffDataSet.billingStart.toString()} />
			<ListItem title={t('supplier')} value={tariffDataSet?.tariff?.supplier?.name} />
			<ListItem title={t('plan')} value={tariffDataSet?.tariff?.plan?.name} />
			<ListItem title={t('cycle')} value={tariffDataSet?.tariff?.cycle?.name} />
			<ListItem title={t('contracted_power')} value={`${tariffDataSet.tariff.power.name}`} />
			<ListItem title={t('address')} value={device?.address} />
		</Box>
	);
};

export default TarifPlanItem;
