import {
	Box, Stack, Checkbox, FormControlLabel, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, DefaultInput,
} from 'common/presentation/components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRegistration } from '../../domain/entities/registration';
import { validateEmail, validatePassword } from 'utils/validation';
import AuthenticationIllistration from './components/AuthenticationIllistration';
import AppLinks from 'assets/applinks.routes';

const RegistrationSection = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	const [formData, setFormData] = useState<IRegistration>({
		fullName: '',
		email: '',
		password: '',
		confirmPassword: '',
		customerType: 0,
	});

	const [errors, setErrors] = useState<IRegistration>({
		fullName: '',
		email: '',
		password: '',
		confirmPassword: '',
		customerType: 0,

	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
		// Optionally clear errors on change
		setErrors({ ...errors, [name]: '' });
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const newErrors = { ...errors };
		let isValid = true;

		// Validate each required field
		if (!formData.fullName) {
			isValid = false;
			newErrors.fullName = t('please_enter_your_name');
		}
		if (!formData.email) {
			isValid = false;
			newErrors.email = t('please_enter_your_email');
		} else if (!validateEmail(formData.email)) {
			isValid = false;
			newErrors.email = t('email_error');
		}
		if (!validatePassword(formData.password)) {
			isValid = false;
			newErrors.password = t('password_error');
		}
		if (formData.confirmPassword !== formData.password) {
			isValid = false;
			newErrors.confirmPassword = t('password_must_match');
		}

		setErrors(newErrors);

		if (isValid) {
			console.log('Submitting form', formData);
			// Here you would handle the form submission, e.g., sending data to an API
		}
	};
	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<Stack direction={{ md: 'row', xs: 'column' }} spacing={2} alignItems="center" justifyContent="center">
				<Stack
					alignItems="center"
					justifyContent="center"
					sx={{
						width: {
							md: '50%',
							xs: '100%',
						},
					}}
				>
					<AuthenticationIllistration
						height={{ md: Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT, xs: '45vh' }}
						width={{ md: Dimens.REGISTRATION_SIDE_ILLUSTRATION_HEIGHT, xs: '100%' }}
						titleText={t('create_account').toUpperCase()}
						titleLink={AppLinks.STORE_AREA_REGISTRATION}
						titleFontSize={{ md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE }}
					/>
				</Stack>
				<Box sx={{ width: { md: '50%', xs: '100%' } }}>
					<Stack spacing={2}>
						<DefaultInput
							label={t('what_is_your_name')}
							placeholder={t('your_full_name')}
							name="fullName"
							value={formData.fullName}
							onChange={handleChange}
							error={!!errors.fullName}
							helperText={errors.fullName}
						/>
						<DefaultInput
							label={t('what_is_your_email')}
							placeholder={t('your_email')}
							name="email"
							value={formData.email}
							onChange={handleChange}
							error={!!errors.email}
							helperText={errors.email}
						/>
						<DefaultInput
							label={t('choose_a_password')}
							placeholder={t('********')}
							name="password"
							type="password"
							value={formData.password}
							onChange={handleChange}
							error={!!errors.password}
							helperText={errors.password}
						/>
						<DefaultInput
							label={t('confirm_your_password')}
							placeholder={t('********')}
							name="confirmPassword"
							type="password"
							value={formData.confirmPassword}
							onChange={handleChange}
							error={!!errors.confirmPassword}
							helperText={errors.confirmPassword}
						/>
						<Box>
							<FormControlLabel
								control={<Checkbox name="private" />}
								label="Private"
							/>
							<FormControlLabel
								control={<Checkbox name="business" />}
								label="Business"
							/>
						</Box>
						<Link
							to={AppLinks.STORE_BECOME_DISTRIBUTER}
						>
							{t('want_to_become_iokee_distributor')}
						</Link>
					</Stack>
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							pt: 5,
						}}
					>
						<DefaultButton type="submit">
							{t('create_account')}
						</DefaultButton>
						<BodyText sx={{ mt: 3 }}>
							{t('already_have_an_account')}
							<span style={{ paddingLeft: Dimens.REGISTRATION_PAGE_SIZE }}>
								<Link
									to={AppLinks.STORE_AREA_LOGIN}
									style={{
										textDecoration: 'none',
										color: theme.palette.primary.main,
										fontWeight: Dimens.FONT_WEIGHT_BOLDER,
									}}
								>
									{t('Login')}
								</Link>
							</span>
						</BodyText>

					</Box>
				</Box>
			</Stack>
		</Box>
	);
};

export default RegistrationSection;
