import React, { FC } from 'react';
import {
	Box, Container, Grid, Link, Typography,
} from '@mui/material';
import {
	Formik, Form,
} from 'formik';
import { useTranslation } from 'react-i18next';
import { logoBlack } from 'assets/icons';
import Space from 'common/presentation/components/space/Space';
import { DefaultButton, DefaultInput } from 'common/presentation/components';
import { useBackofficeAuth } from '../provider/auth.provider';
import { useNavigate } from 'react-router-dom';

const BackofficeLoginPage: FC = () => {
	const {
		initialValues, submit, validate,
	} = useBackofficeAuth();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	return (
		<Container>
			<Container maxWidth="xs" sx={{ pt: 5 }}>

				<img src={logoBlack as string} height="100%" width="100%" alt="AJ Energy Logo" />

				<Space height={3} />

				<Formik
					initialValues={initialValues}
					validateOnMount={false}
					validateOnBlur={false}
					validateOnChange={false}
					onSubmit={submit}
					validate={validate}

				>
					{({
						handleChange, values, errors, isSubmitting,
					}) => (
						<Form autoComplete="off">
							<DefaultInput
								name="username"
								id="username"
								type="email"
								label={t('email')}
								placeholder={t('email')}
								aria-label={t('email')}
								value={values.username}
								error={!!errors.username}
								helperText={errors.username}
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<DefaultInput
								name="password"
								id="pasword"
								aria-label={t('password')}
								type="password"
								label={t('password')}
								placeholder={t('password')}
								value={values.password}
								error={!!errors.password}
								helperText={errors.password}
								onChange={handleChange}
								disabled={isSubmitting}
							/>

							<Space height={2} />

							<DefaultButton
								id="submit"
								name="submit"
								data-testid="submit"
								type="submit"
								loading={isSubmitting}
								fullWidth
							>
								{t('authenticate')}
							</DefaultButton>
						</Form>
					)}
				</Formik>
				<Space height={1} />
				<Grid container sx={{ textAlign: 'center' }}>
					<Grid item xs>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link component="button" onClick={() => navigate('/')} variant="caption">
							{t('forgotPassword')}
						</Link>
					</Grid>
				</Grid>
				<Space height={2} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						IOKEE
						{' '}
						-
						{' '}
						{t('copyright')}
						{' '}
						©
						{' '}
						{new Date().getFullYear()}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default BackofficeLoginPage;
