import {
	Box,
	Dialog, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../../domain/entities/product-type-enum';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { Close, East } from '@mui/icons-material';

interface Props {
	open: boolean
	onClose: () => void
	productType?: ProductType
}

const WhatInBoxDialog: FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const { open, onClose, productType } = props;

	const getTitle = () => {
		let title = '';
		switch (productType) {
		case ProductType.MONO_PHASE_ANALYZER:
			title = t('what_in_mono_analyzer_box');
			break;
		case ProductType.TRI_PHASE_ANALYZER:
			title = t('what_in_tri_analyzer_box');
			break;
		case ProductType.MONO_PHASE_WALLBOX:
			title = t('what_in_mono_wallbox_box');
			break;
		case ProductType.THREE_PHASE_WALLBOX:
			title = t('what_in_tri_wallbox_box');
			break;
		case ProductType.HUB:
			title = t('what_in_hub_box');
			break;
		default:
			break;
		}
		return title;
	};

	const renderList = () => {
		let items: string[] = [];
		switch (productType) {
		case ProductType.MONO_PHASE_ANALYZER:
			items = [
				t('one_mono_analyzer'),
				t('one_sensor'),
				t('two_connectors'),
				t('one_power_connector'),
				t('one_user_manual'),
			];
			break;
		case ProductType.TRI_PHASE_ANALYZER:
			items = [
				t('one_tri_analyzer'),
				t('three_sensor'),
				t('one_connectors'),
				t('one_power_connector'),
				t('one_user_manual'),
			];
			break;
		case ProductType.MONO_PHASE_WALLBOX:
			items = [
				t('one_mono_wallbox'),
				t('one_user_manual'),
			];
			break;
		case ProductType.THREE_PHASE_WALLBOX:
			items = [
				t('one_three_phase_wallbox'),
				t('one_user_manual'),
			];
			break;
		case ProductType.HUB:
			items = [
				t('one_hub'),
				t('one_user_manual'),
			];
			break;
		default:
			break;
		}
		return items;
	};

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>
				{getTitle()}
				<IconButton
					onClick={onClose}
					sx={{
						ml: 5,
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<List>
					{renderList().map((el) => (
						<Box key={el}>
							<ListItem>
								<ListItemIcon>
									<East />
								</ListItemIcon>
								<ListItemText primary={(
									<BodyText fontWeight={Dimens.FONT_WEIGHT_BOLD}>
										{el}
									</BodyText>
								)}
								/>
							</ListItem>
							<Divider />
						</Box>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};

export default WhatInBoxDialog;
