import { BodyText, TitleText } from 'common/presentation/components';
import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';

interface Props {
	title?: string;
	icon?: string;
	description?: string;
	showEmpty?: boolean
}

const FeatureItem: React.FC<Props> = (props) => {
	const {
		title, icon, description, showEmpty,
	} = props;
	const theme = useTheme();

	return (
		<Box sx={{
			border: `1.5px solid ${!showEmpty ? theme.palette.textMain.main : theme.palette.primary.main}`,
			p: 2,
			width: Dimens.FEATURE_ITEM_WIDTH,
			minWidth: Dimens.FEATURE_ITEM_MIN_WIDTH,
			height: Dimens.FEATURE_ITEM_HEIGHT,
			borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
			overflow: 'hidden',
		}}
		>
			<Stack spacing={2} direction="row" alignItems="center">
				<TitleText
					sx={{
						color: theme.palette.primary.main,
						fontSize: {
							md: Dimens.BODY_TEXT_ML,
							xs: Dimens.BODY_TEXT_ML_MOBILE,
							sm: Dimens.BODY_TEXT_ML_MOBILE,
						},
						mt: 3,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
				>
					{title?.toUpperCase()}
				</TitleText>
				{icon && <img src={icon} alt="Feature Icon" />}
			</Stack>
			<BodyText sx={{ fontSize: { md: Dimens.BODY_TEXT_FEATURE_ITEM, xs: Dimens.BODY_TEXT_FEATURE_ITEM_MOBILE, sm: Dimens.BODY_TEXT_FEATURE_ITEM_MOBILE } }}>
				{description}
			</BodyText>
		</Box>
	);
};

export default FeatureItem;
