/* eslint-disable no-console */
import {
	Box,
	Skeleton,
	Stack,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
// import ProductCardItem from './components/ProductCardItem';
// import { useTranslation } from 'react-i18next';
// import { iokeeMonoTopLeft, iokeeTriTopLeft } from 'assets/images/iokee-images';
import { DefaultButton, ProductCarouselCard, TitleText } from 'common/presentation/components';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStoreProducts } from '../provider/product-provider';

const EnergyEfficiencyControllerPhases = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { productById, loading } = useStoreProducts();

	const handleGoBack = () => navigate(-1);

	return (
		<Box sx={{
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			mb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
		}}
		>
			<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={handleGoBack}>
				{t('go_back_to_analyzers')}
			</DefaultButton>
			{loading && (
				<Skeleton
					width="25%"
					height={35}
					sx={{ mt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE } }}
				/>
			)}
			<TitleText
				sx={{ mt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE } }}
			>
				{productById?.name.toUpperCase()}
			</TitleText>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				justifyContent="center"
				alignItems="center"
				spacing={20}
				id="energy-efficiency-controller-phases"
				sx={{
					mt: {
						md: Dimens.FOOTER_PADDING_VERTICAL,
						xs: Dimens.FOOTER_PADDING_VERTICAL_MOBILE,
					},
				}}
			>
				{loading && [1, 2].map((el) => (
					<Skeleton
						key={el}
						width="40%"
						height={400}
						sx={{ mt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE } }}
					/>
				))}
				{!loading && (
					<ProductCarouselCard
						price={productById?.price}
						description={productById?.description}
						product={productById}
					/>
				)}
			</Stack>
		</Box>

	);
};

export default EnergyEfficiencyControllerPhases;
