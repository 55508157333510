/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

interface Props {
	graphData: number[];
}

const ConsumptionChart: React.FC<Props> = (props) => {
	const { graphData } = props;
	const { t } = useTranslation('translations');

	const state = {
		series: [{
			data: graphData,
		}],
	};

	const options: ApexOptions = {
		chart: {
			type: 'bar',
			height: 430,
		},
		plotOptions: {
			bar: {
				barHeight: '99%',
				distributed: true,
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
				borderRadius: 11.5,
			},
		},
		colors: ['#4DFFFF', '#7F7F96', '#23233F',
		],
		dataLabels: {
			enabled: true,
			offsetX: -6,
			style: {
				fontSize: '12px',
				colors: ['#000', '#fff', '#fff'],
			},
			formatter(val) {
				return `${val} Kwh`;
			},
		},
		tooltip: {
			enabled: false,
		},
		legend: { show: false },
		xaxis: {
			categories: [t('estimated'), t('last_month'), t('current')],
		},
		grid: {
			show: false,
			borderColor: '#7f7f96',
			strokeDashArray: 0,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
			row: {
				colors: undefined,
				opacity: 1,
			},
			column: {
				colors: undefined,
				opacity: 1,
			},
		},
	};
	return (
		<div>
			<ReactApexChart options={options} series={state.series} type="bar" height={430} />
		</div>
	);
};
export default ConsumptionChart;
