import { InclinedArrow } from 'assets/icons';
import React from 'react';
import Dimens from 'assets/dimens';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { CircularProgress, useTheme } from '@mui/material';

interface Props extends LoadingButtonProps {
	children: string
}

const DefaultButton: React.FC<Props> = (props) => {
	const {
		children, sx, variant = 'outlined', color, ...rest
	} = props;
	const theme = useTheme();
	const getColor = () => {
		if (variant === 'text') {
			return color;
		}
		if (variant === 'contained') {
			return 'primary';
		}
		return 'inherit';
	};
	return (
		<LoadingButton
			variant={variant}
			size="small"
			color={getColor()}
			loadingIndicator={<CircularProgress color="primary" size={25} />}
			endIcon={<InclinedArrow />}
			sx={{
				pl: Dimens.BUTTON_PADDING_HORIZANTAL,
				pr: Dimens.BUTTON_PADDING_HORIZANTAL,
				pt: Dimens.BUTTON_PADDING_VERTICAL,
				pb: Dimens.BUTTON_PADDING_VERTICAL,
				borderRadius: 50 / 2,
				borderWidth: 1.5,
				fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				color: variant === 'text' ? color : theme.palette.primary.contrastText,
				transition: 'background-color 0.7s ease, border 0.7s ease',
				'&:hover': {
					bgcolor: variant !== 'outlined' ? '' : theme.palette.primary.main,
					border: variant !== 'outlined' ? '' : `1px solid ${theme.palette.primary.main}`,
				},
				...sx,
			}}
			{...rest}
		>
			{children}
		</LoadingButton>
	);
};

export default DefaultButton;
