import React from 'react';
import {
	Box, Paper, IconButton, useTheme,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { chargerClient } from 'assets/icons/';
import { useTranslation } from 'react-i18next';

interface ChargingCardProps {
  time: string;
  duration: string;
  energy: string;
  amount: string;
  email: string;
  selected?: boolean;
  onClick: () => void;
}

const ChargingCard: React.FC<ChargingCardProps> = ({
	time, duration, energy, amount, email, selected = false, onClick,
}) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Paper
			onClick={onClick}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				padding: Dimens.TEXT_DEFAULT,
				marginBottom: Dimens.MARGIN_BOTTOM_CHARGING_CARD,
				backgroundColor: selected ? theme.palette.secondary.main : theme.palette.background.default,
				borderRadius: Dimens.BUTTON_TEXT_MOBILE,
				boxShadow: 'none',
				width: '100%',
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: !selected ? theme.palette.secondary.main : undefined,
				},
				transition: 'background-color 0.3s',
			}}
		>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<BodyText sx={{
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
					color: theme.palette.textLight.main,
					fontSize: Dimens.BUTTON_TEXT_MOBILE,
				}}
				>
					{time}
				</BodyText>

				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{/* Use img tag for chargerClient */}
					<img
						src={chargerClient}
						alt="charger"
					/>
					<Box sx={{
						display: 'flex', flexDirection: 'column', justifyContent: 'center', ml: 1,
					}}
					>
						<BodyText sx={{
							color: theme.palette.textMain.main,
							fontSize: Dimens.BODY_TEXT_S,
							lineHeight: 2, // Control line height for better text alignment
						}}
						>
							{duration}
						</BodyText>
						<BodyText sx={{
							color: theme.palette.textMain.main,
							fontSize: Dimens.BODY_TEXT_S,
							lineHeight: 1, // Keeps both lines close together
						}}
						>
							{energy}
						</BodyText>
					</Box>
				</Box>

				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<BodyText sx={{
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						color: theme.palette.textMain.main,
						fontSize: Dimens.BODY_TEXT_S,
						mr: 1,
					}}
					>
						{amount}
						<span style={{
							fontSize: Dimens.BODY_TEXT_M,
							marginLeft: Dimens.REGISTRATION_PAGE_SIZE,
						}}
						>
							€
						</span>
					</BodyText>
					<IconButton sx={{ p: '0', marginLeft: Dimens.MARGIN_BOTTOM_CHARGING_CARD }}>
						<InfoIcon sx={{ color: theme.palette.textMain.main, fontSize: Dimens.TITLE_TEXT_S }} />
					</IconButton>
				</Box>
			</Box>

			<Box sx={{ marginTop: Dimens.MARGIN_BOTTOM_CHARGING_CARD }}>
				<BodyText sx={{ color: theme.palette.textLight.main, fontSize: Dimens.BUTTON_TEXT_MOBILE }}>
					{t('charging_by')}
					{email}
				</BodyText>
			</Box>
		</Paper>
	);
};

export default ChargingCard;
