/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { CommunityApiImpl } from '../remote/community-api-impl';
import { ICommunityRepository } from '../../domain/repositories/community-repository';
import { Comment } from '../../domain/entities/comment';
import { Article } from '../../domain/entities/article';
import { ICommunityReq } from '../../domain/entities/community';
import { ApiResponse } from 'common/data/entities/api-response';
import { ErrorResponse } from 'common/domain/entities/error-response';

export class CCommunityRepositoryImpl implements ICommunityRepository {
	communityApi = new CommunityApiImpl();

	async getComments(): Promise<Either<string, Comment[]>> {
		try {
			const comments = await this.communityApi.getComments();
			return new Right(comments);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getArticles(lang: string): Promise<Either<string, Article[]>> {
		try {
			const articles = await this.communityApi.getArticles(lang);
			return new Right(articles);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getArticleById(id: number, lang: string): Promise<Either<string, Article>> {
		try {
			const blogs = await this.communityApi.getArticleById(id, lang);
			return new Right(blogs); // Fixed this line
		} catch (error) {
			return new Left('An Error Occurred'); // Fixed the typo
		}
	}

	async joinCommunity(params: ICommunityReq): Promise<Either<ErrorResponse, ApiResponse<null>>> {
		try {
			const response = await this.communityApi.joinCommunity(params);
			return new Right(response);
		} catch (error: any) {
			return new Left(error);
		}
	}
}
