import Dimens from 'assets/dimens';
import { ElectricCarIcon } from 'assets/find-your-iokee-icons';
import {
	CounterButton,
	DefaultButton,
	TitleText,
} from 'common/presentation/components';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Stack, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxCard from '../components/CheckboxCard';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { IQuestion } from '../../domain/question.model';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
	question?: IQuestion
}

const ElectricCarSection: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick, question } = props;
	const { t } = useTranslation('translations');
	const {
		handleValueChange, getValue, isAnyAnswerSelected, updateDevicesQuantity,
	} = useFindYourIokee();
	const [isNumberOfCarView, setIsNumberOfCarsView] = useState(false);

	const [electricCarQuantity, setElectricCarsQuantity] = useState(1);
	const [isFirstAnswerYes, setIsFirstAnswerYes] = useState(false);

	const handleContinueClick = () => {
		if (!isNumberOfCarView && isFirstAnswerYes) {
			setIsNumberOfCarsView(true);
		} else {
			onContinueClick();
		}
	};

	const handleNumCarsChange = (newVal: number) => {
		if (newVal > 0) {
			setElectricCarsQuantity((prev) => {
				if (question) {
					updateDevicesQuantity(question?.id, question?.answers[0].id, prev + 1);
				}
				return prev + 1;
			});
		} else if (newVal < 0) {
			if (electricCarQuantity <= 1) {
				setElectricCarsQuantity(1);
				if (question) {
					updateDevicesQuantity(question?.id, question?.answers[0].id, 1);
				}
			} else {
				setElectricCarsQuantity((prev) => {
					if (question) {
						updateDevicesQuantity(question?.id, question?.answers[0].id, prev - 1);
					}
					return prev - 1;
				});
			}
		}
	};

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 6 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
			>
				{question?.description.toUpperCase()}
			</TitleText>
			<Stack alignItems="center" justifyContent="flex-end">
				<SvgIcon sx={{ fontSize: { md: 100, xs: 80, sm: 80 } }} color="inherit" component="div" inheritViewBox>
					<ElectricCarIcon />
				</SvgIcon>
			</Stack>
			<Stack direction="row" spacing={6} alignItems="center">
				{!isNumberOfCarView && (
					question?.answers.map((el, index) => (
						<CheckboxCard
							key={el.id}
							selected={getValue(question.id, el.id)}
							title={el.text.toUpperCase()}
							onClick={() => {
								handleValueChange(question.id, el.id);
								if (index === 0) {
									setIsFirstAnswerYes(false);
								} else {
									setIsFirstAnswerYes(true);
								}
							}}
						/>
					))
				)}
			</Stack>
			{
				isNumberOfCarView && (
					<Stack direction="row" spacing={6} alignItems="center">
						<CounterButton
							increment={() => handleNumCarsChange(1)}
							decrement={() => handleNumCarsChange(-1)}
							count={electricCarQuantity}
						/>
					</Stack>
				)
			}
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton
					disabled={!isNumberOfCarView && !isAnyAnswerSelected(question?.id)}
					onClick={handleContinueClick}
					variant="contained"
					endIcon={<ArrowForward />}
				>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default ElectricCarSection;
