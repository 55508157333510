import React, { useState } from 'react';
import {
	Modal, Box, IconButton, TextField, Stack, useTheme, InputAdornment,
} from '@mui/material';
import {
	facebook, instagram, linkedin, mail, whatsapp, x, close, contentCopy,
} from 'assets/images/social-media';
import { BodyText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import useFeedback from 'common/presentation/providers/feedback.provider';

interface SocialMediaShareLinkProps {
  open: boolean;
  handleClose: () => void;
  shareLink: string;
}

const socialIcons = [
	{
		icon: <img src={mail} alt="E-mail" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'E-mail',
		getShareUrl: (link: string) => `mailto:?body=${link}`,
	},
	{
		icon: <img src={whatsapp} alt="WhatsApp" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'WhatsApp',
		getShareUrl: (link: string) => `https://api.whatsapp.com/send?text=${link}`,
	},
	{
		icon: <img src={instagram} alt="Instagram" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'Instagram',
		getShareUrl: () => 'https://www.instagram.com/',
	},
	{
		icon: <img src={linkedin} alt="LinkedIn" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'LinkedIn',
		getShareUrl: (link: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
	},
	{
		icon: <img src={facebook} alt="Facebook" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'Facebook',
		getShareUrl: (link: string) => `https://www.facebook.com/sharer/sharer.php?u=${link}`,
	},
	{
		icon: <img src={x} alt="X" style={{ height: Dimens.COMMUNITY_BLOG_MODEL_ICONS_HEGIHT }} />,
		label: 'X',
		getShareUrl: (link: string) => `https://twitter.com/intent/tweet?url=${link}`,
	},
];

const SocialMediaShareLink: React.FC<SocialMediaShareLinkProps> = (
	{ open, handleClose, shareLink },
) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { addToast } = useFeedback();

	const [currentShareLink, setCurrentShareLink] = useState(shareLink);

	const handleCopy = () => {
		navigator.clipboard.writeText(currentShareLink);
		addToast({ message: t('link_copied'), error: false });
	};

	const handleIconClick = (getShareUrl: (link: string) => string) => {
		const url = getShareUrl(shareLink);
		setCurrentShareLink(url);
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xs: '90%', md: '50%' },
					maxWidth: Dimens.COMMUNITY_BLOG_MODEL_WIDTH,
					minHeight: Dimens.MODEL_HEIGHT_SINGLE_PHASE,
					bgcolor: theme.palette.background.default,
					p: 3,
					borderRadius: Dimens.BODY_TEXT_S,
					py: 5,
				}}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
					<BodyText
						sx={{ fontSize: Dimens.BODY_TEXT_XL, fontWeight: Dimens.FONT_WEIGHT_BOLD }}
					>
						{t('share_post_social').toUpperCase()}
					</BodyText>
					<IconButton onClick={handleClose}>
						<img src={close} alt="Close" style={{ height: Dimens.CLIENT_AREA_PADDING_BOTTOM }} />
					</IconButton>
				</Stack>
				<TextField
					fullWidth
					variant="outlined"
					value={currentShareLink}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Stack
									direction="row"
									alignItems="center"
									onClick={handleCopy}
									sx={{
										borderRadius: Dimens.CLIENT_AREA_LOGIN_IMAGE_BORDER_RADIUS,
										px: 1,
										border: `1px solid ${theme.palette.textMain.main}`,
										backgroundColor: theme.palette.background.default,
										cursor: 'pointer',
									}}
								>
									<BodyText sx={{ fontSize: Dimens.BODY_TEXT_S }}>{t('copy')}</BodyText>
									<IconButton>
										<img
											src={contentCopy}
											alt="Copy"
											style={{ height: Dimens.CLIENT_AREA_PAGE_PADDING_MOBILE }}
										/>
									</IconButton>
								</Stack>
							</InputAdornment>
						),
						readOnly: true,
						sx: {
							borderRadius: Dimens.CLIENT_AREA_LOGIN_IMAGE_BORDER_RADIUS,
							backgroundColor: theme.palette.customGrey.main,
							mt: 3,
						},
					}}
				/>

				<Stack direction="row" spacing={2} justifyContent="space-between" mt={6}>
					{socialIcons.map((social) => (
						<Box key={social.label} sx={{ textAlign: 'center', width: Dimens.CLIENT_AREA_PAGE_PADDING }}>
							<IconButton
								sx={{
									color: social.label === 'WhatsApp'
										? theme.palette.success.main : theme.palette.textMain.main,
								}}
								onClick={() => handleIconClick(social.getShareUrl)}
							>
								{social.icon}
							</IconButton>
							<BodyText sx={{ fontSize: Dimens.CARD_PADDING_MOBILE }}>{social.label}</BodyText>
						</Box>
					))}
				</Stack>
			</Box>
		</Modal>
	);
};

export default SocialMediaShareLink;
