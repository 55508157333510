import {
	BodyText, CounterButton, DefaultButton, TitleText,
} from 'common/presentation/components';
import { formatPrice } from 'utils/price.utils';
import {
	Box, IconButton, Stack, useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { AddShoppingCart } from '@mui/icons-material';
import { IStoreProduct } from '../../domain/entities/product';
import useCart from 'common/presentation/providers/cart.provider';
import Space from 'common/presentation/components/space/Space';

interface Props {
	product?: IStoreProduct | null
	productImage?: string
	title: string
	price: number
	storageInfo?: string
	handleSeeMoreClick: () => void
}

const ProductItem: FC<Props> = (props) => {
	const {
		product,
		productImage,
		title,
		price,
		storageInfo,
		handleSeeMoreClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const { addToCart } = useCart();
	const [count, setCount] = React.useState(1);

	const handleIncrement = () => {
		setCount((prevCount) => prevCount + 1);
	};

	const handleDecrement = () => {
		setCount((prevCount) => {
			if (prevCount === 1) return prevCount;
			return prevCount - 1;
		});
	};

	const handleAddToCartClick = () => {
		if (product) {
			addToCart({
				productId: product.id,
				name: product.name,
				price: product.price,
				originalPrice: product.price,
				storageFee: 1,
				freeStorageTime: 2,
				image: productImage || '',
				description: product.description,
				qty: count,
			});
		}
	};

	return (
		<Stack
			sx={{
				minWidth: {
					md: Dimens.CATEGORY_PRODUCT_CARD_WIDTH,
					xs: Dimens.CATEGORY_PRODUCT_CARD_WIDTH_MOBILE,
					sm: Dimens.CATEGORY_PRODUCT_CARD_WIDTH_MOBILE,
				},
				height: {
					md: Dimens.CATEGORY_PRODUCT_CARD_HEIGHT,
					sm: Dimens.CATEGORY_PRODUCT_CARD_HEIGHT_MOBILE,
					xs: Dimens.CATEGORY_PRODUCT_CARD_HEIGHT_MOBILE,
				},
				border: `1.5px solid ${theme.palette.textMain.main}`,
				borderRadius: Dimens.SMALL_IMAGE_BORDER_RADIUS,
				pt: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION_MOBILE },
				pb: { md: Dimens.PADDING_TOP_MISSION, xs: Dimens.PADDING_TOP_MISSION_MOBILE },
				pl: { md: Dimens.BUTTON_PADDING_HORIZANTAL, xs: Dimens.BUTTON_PADDING_HORIZANTAL_MOBILE },
				pr: { md: Dimens.BUTTON_PADDING_HORIZANTAL, xs: Dimens.BUTTON_PADDING_HORIZANTAL_MOBILE },
				transition: 'all 0.25s',
				'&:hover': {
					border: `1.5px solid ${theme.palette.primary.main}`,
					boxShadow: '2px 2px 6px 0px rgba(77, 255, 255, 0.6)',
				},
				'&:hover div div button': {
					bgcolor: theme.palette.primary.main,
					border: `1px solid ${theme.palette.primary.main}`,
				},
				cursor: 'pointer',
			}}
			justifyContent="space-between"
		>
			<img
				src={productImage}
				alt={title}
				style={{
					alignSelf: 'center',
					width: '200px',
					height: '170px',
					objectFit: 'contain',
				}}
			/>
			<Box>
				<TitleText
					sx={{
						fontSize: { md: Dimens.TITLE_TEXT_S, xs: Dimens.TITLE_TEXT_S_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					dangerouslySetInnerHTML={{ __html: title.toUpperCase() }}
				/>
				<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
					<Stack direction="row" alignItems="center">
						<BodyText
							sx={{
								fontWeight: Dimens.FONT_WEIGHT_BOLD,
								fontSize: { md: Dimens.TITLE_TEXT_S, xs: Dimens.TITLE_TEXT_S_MOBILE },
							}}
						>
							{formatPrice(price)}
						</BodyText>
						<BodyText
							sx={{ fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE } }}
						>
							{' '}
							{t('with_tax')}
						</BodyText>
					</Stack>
				</Stack>
				{storageInfo && (
					<>
						<BodyText>{storageInfo}</BodyText>
						<Space height={2} />
					</>
				)}
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					{product && (
						<Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
							<CounterButton
								count={count}
								increment={handleIncrement}
								decrement={handleDecrement}
							/>
							<IconButton onClick={handleAddToCartClick}>
								<AddShoppingCart />
							</IconButton>
						</Stack>
					)}
					<DefaultButton onClick={handleSeeMoreClick}>
						{t('learn_more')}
					</DefaultButton>
				</Stack>
			</Box>
		</Stack>
	);
};

export default ProductItem;
