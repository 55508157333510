import React from 'react';
import {
	Box, Divider, Stack, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import useCommunity from '../provider/community.provider';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import i18n from 'assets/translations';
import { useTranslation } from 'react-i18next';
import AppLinks from 'assets/applinks.routes';
import CardArticle from '../iokee-community/components/CardArticle';

const BlogBody = () => {
	const theme = useTheme();
	const {
		getArticleById, articles, getArticles, article,
	} = useCommunity();
	const { t } = useTranslation('translations');
	const { id } = useParams<{ id: string }>();
	const lang = i18n.language;
	const [expanded, setExpanded] = React.useState(false);
	const navigate = useNavigate();

	React.useEffect(() => {
		if (id) {
			getArticleById(parseInt(id, 10), lang);
		}
		getArticles(lang);
	}, [id, lang, getArticleById, getArticles]);

	const handleReadMoreClick = () => {
		setExpanded(!expanded);
	};

	const handleStoryClick = (mId: number) => {
		navigate(AppLinks.COMMUNITY_DETAIL.replace(':id', `${mId}`));
	};

	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: Dimens.APP_BAR_MARGIN_BOTTOM,
				mb: Dimens.TITLE_BODY_SPACING_MOBILE,
			}}
			>
				<TitleText
					sx={{ fontSize: Dimens.TITEL_TEXT_M, fontWeight: Dimens.FONT_WEIGHT_BOLD }}
				>
					{article?.title.toUpperCase()}
				</TitleText>
			</Box>
			<Divider sx={{
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			/>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			>
				<Stack direction="row" justifyContent="flex-end" sx={{ mt: Dimens.TITLE_SPACING }}>
					<BodyText sx={{
						color: theme.palette.textMain.main,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					>
						{article?.tags.join(' | ')}
					</BodyText>
					<BodyText sx={{ ml: 2, color: theme.palette.textMain.main }}>
						|
					</BodyText>
					<BodyText sx={{
						ml: 2,
						color: theme.palette.textMain.main,
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
					}}
					>
						{moment(article?.publishedDate).format('DD MMM YYYY')}
					</BodyText>
				</Stack>
			</Box>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING_LARGE, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			>
				<Box sx={expanded ? { position: 'relative', overflow: 'hidden' } : {}}>
					<Box sx={expanded ? { maxHeight: 'none' } : {
						// eslint-disable-next-line max-len
						'-webkit-mask-image': '-webkit-gradient(linear, center top, center bottom, color-stop(0.00,  rgba(0,0,29,1)), color-stop(1.00,  rgba(0,0,29,0)))',
						display: 'block',
						padding: '20px',
						transition: 'max-height 1s',
						maxHeight: '40%',
						overflow: 'hidden',
					}}
					>
						<Box
							component="div"
							sx={{ color: theme.palette.textMain.main }}
							dangerouslySetInnerHTML={{
								__html: (expanded
									? article?.content : article?.content.slice(0, 1000)) || '',
							}}
						/>
					</Box>
				</Box>
				<Stack direction="row" justifyContent="flex-end" sx={{ mt: Dimens.TITLE_SPACING }}>
					<DefaultButton onClick={handleReadMoreClick}>
						{expanded ? t('show_less') : t('read_more_about_topic')}
					</DefaultButton>
				</Stack>
			</Box>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			}}
			>
				<TitleText sx={{ fontSize: Dimens.TITLE_TEXT_L }}>{t('other_stories').toUpperCase()}</TitleText>
				<Stack
					direction="row"
					flexWrap="wrap"
					spacing={4}
					useFlexGap
					sx={{ mt: Dimens.FOOTER_PADDING_VERTICAL }}
				>
					{articles?.map((el, index) => {
						if (index < 3 && el.id !== parseInt(id || '', 10)) {
							return (
								<CardArticle article={el} onNavigate={handleStoryClick} />
							);
						}
						return null;
					})}
				</Stack>
			</Box>
		</section>
	);
};

export default BlogBody;
