import { BodyText } from 'common/presentation/components';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeCommunityRequest } from './provider/community-req-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from 'utils/date/date.utils';

const BackofficeCommunityRequestsPage = () => {
	const { t } = useTranslation('translations');
	const {
		communityRequests, getCommunityRequests, loading,
	} = useBackofficeCommunityRequest();

	useEffectCustom(() => {
		getCommunityRequests();
	}, []);

	return (
		<Box>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('community_requests')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
			</Stack>
			<Box className="card" mt={6}>
				<DataTable
					value={communityRequests}
					size="normal"
					loading={loading}
					dataKey="id"
					tableStyle={{ minWidth: '50rem' }}
				>
					<Column field="id" header="ID" align="center" />
					<Column field="fullName" header={t('name')} align="center" />
					<Column field="email" header={t('email')} align="center" />
					<Column field="country" header={t('country')} align="center" />
					<Column field="createDate" header={t('created_on')} align="center" body={(options) => formatDate(options.createDate, 'DD-MM-yyyy')} />
				</DataTable>
			</Box>
		</Box>
	);
};
export default BackofficeCommunityRequestsPage;
