import React, { useState, useRef, useEffect } from 'react';
import {
	Box, Stack, Collapse, CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import ClientBackButton from 'common/presentation/components/client-back-button/ClientBackButton';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import WallboxFilterSection from './components/WallboxFilterSection';
import ChargingDetails from './components/ChargingDetails';
import AppLinks from 'assets/applinks.routes';
import AccordionContent from './components/power-charge-details/AccordionContent';
import EnergyPriceSummary from './components/power-charge-details/EnergyPriceSummary';
import { useWallbox } from './provider/wallbox.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IPageItem } from '../entities/charges.model';
import { IDevice } from '@/features/client-device-list/entities/device';

const WallboxHistoryPage = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const wallBox = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;

	const [expandedCard, setExpandedCard] = useState<string | null>(null);
	const [charge, setCharge] = useState<IPageItem>();
	const accordionRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const {
		charges, chargeList, loading, error,
	} = useWallbox();

	const handleGoBack = () => navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);

	const handleCardClick = (item: IPageItem) => {
		setCharge(item);
		const cardID = item.chargeID.toString();
		setExpandedCard(expandedCard === cardID ? null : cardID);
		if (accordionRef.current && expandedCard !== cardID) {
			clearTimeout(timeoutRef.current || 0);
			timeoutRef.current = setTimeout(() => accordionRef.current?.scrollIntoView({ behavior: 'smooth' }), 300);
		}
	};
	const resetAccordion = () => {
		setExpandedCard(null);
	};
	useEffect(() => () => clearTimeout(timeoutRef.current || 0), []);

	return (
		<Box>
			<ClientBackButton onBackClick={handleGoBack} />

			{/* Display Loading and Error States */}
			{loading && <Stack><CircularProgress /></Stack>}
			{error && <BodyText color="error">{error.message}</BodyText>}

			{/* Title */}
			<Stack direction={{ md: 'row', xs: 'column' }} sx={{ mt: Dimens.TITLE_SPACING }}>
				<BodyText
					sx={{
						fontFamily: 'Aventa',
						fontSize: Dimens.SUBTITLE_TEXT_MOBILE,
						fontStyle: 'italic',
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
						lineHeight: '30.58px',
						color: theme.palette.textLight.main,
					}}
				>
					{wallBox?.name}
					<span
						style={{
							fontSize: Dimens.BODY_TEXT_XL,
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							color: theme.palette.primary.main,
						}}
					>
						{t('history')}
					</span>
				</BodyText>
			</Stack>

			{/* Filter Section */}
			<Stack sx={{ mt: Dimens.TITLE_SPACING }}>
				<WallboxFilterSection onResetAccordion={resetAccordion} />
			</Stack>

			{/* Charging Details Title */}
			<Stack sx={{ mt: Dimens.TITLE_SPACING }}>
				<BodyText sx={{ mt: Dimens.SUBTITLE_TEXT_MOBILE, fontWeight: Dimens.FONT_WEIGHT_BOLD }}>
					{t('charging_details')}
				</BodyText>
			</Stack>

			{/* Main Content - ChargingDetails and EnergyPriceSummary */}
			<Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: Dimens.TITLE_SPACING, width: '100%' }}>
				{/* Left Column - ChargingDetails */}
				<Stack sx={{ width: { xs: '100%', md: '50%' } }}>
					<ChargingDetails
						onCardClick={handleCardClick}
						expandedCard={expandedCard}
						chargeItems={chargeList} // Display updated results based on the search
					/>
				</Stack>

				{/* Right Column - EnergyPriceSummary */}
				<Stack sx={{ width: { xs: '100%', md: '50%' } }}>
					<EnergyPriceSummary
						consumption={(charges.itemsTotalEnergyConsumption ?? 0) / 1000}
						cost={charges.itemsTotalEnergyCost ?? 0}
					/>
				</Stack>
			</Stack>

			{/* Accordion Content */}
			{expandedCard && (
				<Collapse in sx={{ width: '100%', mt: Dimens.MD_SUMMARY_CARD_TITLE }} ref={accordionRef}>
					<AccordionContent charge={charge} />
				</Collapse>
			)}
		</Box>
	);
};

export default WallboxHistoryPage;
