import Dimens from 'assets/dimens';
import {
	Box, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import useCommunity from '../provider/community.provider';

const CommunityTitle = () => {
	const { loading, highlightedComment, getComments } = useCommunity();
	const theme = useTheme();
	const isMd = useMediaQuery(() => theme.breakpoints.up('md'));
	const { t } = useTranslation('translations');

	React.useEffect(() => {
		getComments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section>
			<Stack
				sx={{
					pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				}}
			>
				<Stack direction="row" alignItems="flex-end">
					<TitleText
						size={Dimens.TITLE_TEXT_L}
						sx={{ fontStyle: 'italic' }}
						dangerouslySetInnerHTML={{ __html: t('iokee_community').toUpperCase() }}
					/>
				</Stack>
				<BodyText sx={{ width: { md: '60%', xs: '100%' }, mt: Dimens.TITLE_SPACING }}>
					{t('iokee_community_info')}
				</BodyText>
				{loading && <h1>Loading...</h1>}
				{highlightedComment && (
					<Stack direction={{ md: 'row', xs: 'column' }} spacing={6} sx={{ mt: Dimens.TITLE_BODY_SPACING }}>
						<Box sx={{ flexBasis: isMd ? '50%' : '100%', display: 'flex', justifyContent: 'center' }}>
							<img
								style={{ borderRadius: '33px 1px 27px 0px / 27px 0px 33px 0px' }}
								src={highlightedComment?.image}
								alt="Community"
								width="100%"
								height="auto"
							/>
						</Box>
						<Box sx={{ mt: { md: 2, sm: 4 }, flexBasis: '35%' }}>
							<Box ml={{ md: 10 }}>
								<BodyText
									fontSize={Dimens.TITLE_TEXT_S_MOBILE}
									mt={3}
									dangerouslySetInnerHTML={{ __html: t('we_care') }}
								/>
								<BodyText mt={3} dangerouslySetInnerHTML={{ __html: t('we_believe') }} />
								<BodyText mt={3} dangerouslySetInnerHTML={{ __html: t('we_provide') }} />
								<BodyText mt={3} dangerouslySetInnerHTML={{ __html: t('we_empower') }} />
								<BodyText mt={3} dangerouslySetInnerHTML={{ __html: t('we_support') }} />
							</Box>
						</Box>
					</Stack>
				)}
			</Stack>
		</section>
	);
};

export default CommunityTitle;
