import { BodyText, TitleText } from 'common/presentation/components';
import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';

interface Props {
	title: string;
	description: string;
	count: number;
	isHighlighted?: boolean;
	align?: 'flex-start' | 'flex-end' | 'center'
}

const InstallationSteps: React.FC<Props> = (props) => {
	const {
		title, description, count, isHighlighted, align = 'flex-start',
	} = props;
	const theme = useTheme();
	return (
		<Stack direction="row" alignSelf={align} alignItems="flex-start" columnGap={2} sx={{ maxWidth: '650px' }}>
			<TitleText
				sx={{
					fontSize: {
						md: Dimens.TEXT_SIZE_STEP,
						xs: Dimens.TEXT_SIZE_STEP_MOBILE,
					},
				}}
				color={isHighlighted ? theme.palette.primary.main : theme.palette.textMain.main}
			>
				{count}
			</TitleText>
			<Box
				sx={{
					mt: { md: 5, xs: 2 },
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}
			>
				<TitleText
					sx={{
						fontSize: {
							md: Dimens.TITEL_TEXT_M,
							xs: Dimens.TITLE_TEXT_M_MOBILE,
						},
						fontWeight: Dimens.FONT_WEIGHT_NORMAL,
					}}
					color={isHighlighted ? theme.palette.primary.main : theme.palette.textMain.main}
				>
					{title}
				</TitleText>
				<BodyText sx={{
					mt: 2,
					fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{description}
				</BodyText>
			</Box>
		</Stack>
	);
};

export default InstallationSteps;
