import React from 'react';
import WallboxHistoryPage from './WallboxHistoryPage';
import { WallboxProvider } from './provider/wallbox.provider';

const WallboxDetail = () => (
	<WallboxProvider>
		<WallboxHistoryPage />
	</WallboxProvider>
);

export default WallboxDetail;
