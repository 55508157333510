import Dimens from 'assets/dimens';
import { perfectMatchHubWallbox, perfectMatchImage } from 'assets/images';
import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText, SizedBox, TitleText } from 'common/presentation/components';
import { useStoreProducts } from '../provider/product-provider';

const CATEGORY_HUB = 5;
const CATEGORY_WALLBOX = 3;

const PerfectMatch = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { productById } = useStoreProducts();
	return (
		<Stack
			sx={{
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			direction={{
				lg: 'row',
				xl: 'row',
				md: 'column-reverse',
				xs: 'column-reverse',
				sm: 'column-reverse',
			}}
			justifyContent="space-between"
			alignItems="center"
		>
			<Stack
				alignItems="center"
				sx={{
					width: {
						lg: '30vw',
						xl: '30vw',
						md: '50vw',
						xs: '80vw',
						sm: '80vw',
					},
					mt: {
						lg: 0,
						xl: 0,
						md: Dimens.TITLE_SPACING_MOBILE,
						sm: Dimens.TITLE_SPACING_MOBILE,
						xs: Dimens.TITLE_SPACING_MOBILE,
					},
				}}
			>
				<TitleText sx={{ fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE } }} dangerouslySetInnerHTML={{ __html: t('the_perfect_match').toUpperCase() }} />
				<SizedBox height={3} />
				<Box sx={{
					border: `1.5px solid ${theme.palette.textMain.main}`,
					alignSelf: 'center',
					padding: '12px 24px',
					borderRadius: '30px',
				}}
				>
					<TitleText
						sx={{
							fontSize: {
								md: (productById?.category.id === CATEGORY_HUB || productById?.category.id === CATEGORY_WALLBOX) ? Dimens.TITLE_TEXT_M_MOBILE : Dimens.TITLE_TEXT_S,
								xs: Dimens.TITLE_TEXT_S_MOBILE,
							},
							textAlign: 'center',
						}}
						dangerouslySetInnerHTML={{
							__html: (productById?.category.id === CATEGORY_HUB || productById?.category.id === CATEGORY_WALLBOX)
								? t('analyzer_plus_app_plus_hub_wallbox').toUpperCase() : t('analyzer_plus_app').toUpperCase(),
						}}
					/>
				</Box>
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('the_perfect_match_info')}
				</BodyText>
			</Stack>
			<Box sx={{
				width: {
					lg: '35vw',
					xl: '35vw',
					md: '50vw',
					xs: '80vw',
					sm: '80vw',
				},
			}}
			>
				{(productById?.category.id === CATEGORY_HUB || productById?.category.id === CATEGORY_WALLBOX)
					? <img width="100%" src={perfectMatchHubWallbox} alt="Match" /> : <img width="100%" src={perfectMatchImage} alt="Match" />}
			</Box>
		</Stack>
	);
};

export default PerfectMatch;
