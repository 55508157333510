import React from 'react';

export const formatPrice = (price: number) => {
	const mPrice = (Math.round(price * 100) / 100).toFixed(2);
	const paddedPrice = `${mPrice} €`;
	return paddedPrice.replace('.', ',');
};

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string) => {
	const desiredLength = 3;
	const actualLength = digits.length;

	if (actualLength >= desiredLength) {
		return digits;
	}

	const amountToAdd = desiredLength - actualLength;
	const padding = '0'.repeat(amountToAdd);

	return padding + digits;
};

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number: string) => {
	const centsStartingPosition = number.length - 2;
	const dollars = removeLeadingZeros(
		number.substring(0, centsStartingPosition),
	);
	const cents = number.substring(centsStartingPosition);
	return `${dollars},${cents}`;
};

export const currency = (event: React.ChangeEvent<HTMLTextAreaElement |HTMLInputElement>) => {
	const mEvent = event;
	const { value } = event.target;
	const digits = getDigitsFromValue(value);
	const digitsWithPadding = padDigits(digits);
	mEvent.target.value = addDecimalToNumber(digitsWithPadding);
	return mEvent;
};

export const putDecimalPoints = (value: number | undefined | null) => {
	let val = value?.toFixed(2);
	val = `${val || 0}`;
	if (val.includes('.')) {
		val = val.replace('.', ',');
	} else {
		val = val.concat(',00');
	}
	val = val.replace(/(?=(\d{3})+(\D))\B/g, '.');
	return val;
};

export const normalizeCurrency = (e: string | number) => {
	let value = e.toString();
	value = value.replace('.', '');
	value = value.replace(',', '');
	return parseInt(value, 10) / 100;
};
