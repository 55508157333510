import Dimens from 'assets/dimens';
import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { howIokeeWorksMobile } from 'assets/images';
import { useStoreProducts } from '../provider/product-provider';

const HowIokeeWorks = () => {
	const { t } = useTranslation('translations');
	const { productById } = useStoreProducts();

	return (
		<Stack
			direction="column"
			sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			spacing={2}
			component="section"
		>
			<Grid
				container
				sx={{
					pt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				}}
			>
				<Grid item xs={12} md={5}>
					<TitleText
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						}}
					>
						{t('how_iokee_works').toUpperCase()}
					</TitleText>
				</Grid>
				<Grid item xs={12} md={7}>
					{productById && (
						<BodyText
							size={Dimens.BODY_TEXT_L}
							sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL }}
							dangerouslySetInnerHTML={{ __html: productById?.howItWorks }}
						/>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				sx={{
					pt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				}}
			>
				<Grid item xs={12} md={5}>
					<BodyText
						size={Dimens.BODY_TEXT_XL}
						sx={{
							fontStyle: 'italic',
							fontWeight: Dimens.FONT_WEIGHT_NORMAL,
						}}
					>
						{t('the_key_features').toUpperCase()}
					</BodyText>
				</Grid>
				<Grid item xs={12} md={7}>
					<BodyText
						size={Dimens.BODY_TEXT_L_MOBILE}
						sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL }}
					>
						{t('the_key_features_info')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL }}>
						{t('the_key_features_info_one')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL }}>
						{t('the_key_features_info_two')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_NORMAL }}>
						{t('the_key_features_info_three')}
					</BodyText>
				</Grid>
			</Grid>
			<Box sx={{ display: { md: 'none', xs: 'block' } }}>
				<img src={howIokeeWorksMobile} width="100%" alt="how iokee works" />
			</Box>
		</Stack>
	);
};

export default HowIokeeWorks;
