import {
	Box, Card, Divider, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import BodyText from '../body-text/BodyText';
import { IProductTag } from 'features/client-device-list/entities/device';
import { Tag } from 'primereact/tag';

interface Props {
	title: string;
	deviceName: string;
	address: string;
	onClick: () => void
	deviceImage: string
	tags?: IProductTag[]
}

const DeviceCard: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		title, deviceName, address, onClick, deviceImage, tags,
	} = props;
	return (
		<Card
			onClick={onClick}
			sx={{
				pt: {
					md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL,
					xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE,
				},
				pb: {
					md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL,
					xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE,
				},
				pl: {
					md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL,
					xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL_MOBILE,
				},
				pr: {
					md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL,
					xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL_MOBILE,
				},
				borderRadius: '25px',
				width: '458px',
				// height: '255px',
				cursor: 'pointer',
			}}
		>
			<Box>
				<Stack direction="row" justifyContent="space-between">
					<Box>
						<BodyText sx={{
							fontSize: Dimens.BODY_TEXT_L,
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
						}}
						>
							{title}
						</BodyText>
						<BodyText sx={{
							fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
							color: theme.palette.textLight.main,
						}}
						>
							{deviceName}
						</BodyText>
					</Box>
					<img src={deviceImage.toString()} alt="Device" />
				</Stack>
				<Divider sx={{ mt: 3, mb: 3 }} />
				<BodyText sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_M,
						xs: Dimens.BODY_TEXT_M_MOBILE,
						display: '-webkit-box',
						WebkitLineClamp: 2,
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxHeight: '2.8em',
					},
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{address}
				</BodyText>
				{tags && tags.length !== 0 && (
					<Stack direction="row" flexWrap="wrap" useFlexGap sx={{ mt: 1 }}>
						{tags.map((tag) => (
							<Tag key={tag.name} value={tag.name} className="p-tag-rounded" />
						))}
					</Stack>
				)}
			</Box>
		</Card>
	);
};

export default DeviceCard;
