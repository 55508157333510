/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { FormikHelpers } from 'formik';
import { IBackofficeQuestion, IBackofficeQuestionRequest } from '../questions/entities/question.modal';
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { BackofficeQuestionApiImpl } from '../questions/data/question.api-impl';
import { useTranslation } from 'react-i18next';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { IAnswerReq, IAnswerRes } from '../questions/entities/answer.model';
import { IAnswerRuleReq } from '../questions/entities/rule.model';

interface FetchState {
	loading: boolean
	error: string
}

type Props = {
	formValues: IBackofficeQuestionRequest
	setFormValues: (values: IBackofficeQuestionRequest) => void
	submit: (
		values: IBackofficeQuestionRequest,
		formikHelpers: FormikHelpers<IBackofficeQuestionRequest>,
		id?: string
	) => void;
	questionState: FetchState
	getQuestions: () => void
	questions: IBackofficeQuestion[]
	deleteQuestion: (id: number) => void
	getQuestionById: (id: string) => void
	questionById: IBackofficeQuestion | null
	answer: IAnswerReq
	setAnswer: (value: IAnswerReq) => void
	submitAnswer: (alues: IAnswerReq,
		formikHelpers: FormikHelpers<IAnswerReq>,
		id?: string,) => void
	deleteAnswerById: (id: number, questionId: number) => void
	answrRule: IAnswerRuleReq
	setAnswerRule: (param: IAnswerRuleReq) => void
	addAnswerRule: (questionId: string | undefined, answerId: number) => Promise<boolean>
	answerRuleLoading: boolean
	getAnswersByQuestionId: (id: string | undefined) => void
	answers: IAnswerRes[]
	deleteAnswerRule: (questionId: string | undefined, answerId: number, ruleId: number) => void
}

export const BackofficeQuestionContext = createContext({} as Props);

export const BackofficeQuestionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [formValues, setFormValues] = useState<IBackofficeQuestionRequest>({
		description: '',
		stepOrder: 0,
	});
	const [questionState, setQuestionState] = useState({ loading: false, error: '' });
	const [questions, setQuestions] = useState<IBackofficeQuestion[]>([] as IBackofficeQuestion[]);
	const [questionById, setQuestionById] = useState<IBackofficeQuestion | null>(null);
	const [answers, setAnswers] = useState([] as IAnswerRes[]);
	const [answer, setAnswer] = useState<IAnswerReq>({ text: '' });
	const [answrRule, setAnswerRule] = useState<IAnswerRuleReq>({
		productId: 0,
		devicesHandledByProduct: 0,
		ruleType: 1,
	});
	const [answerRuleLoading, setAnswerRuleLoading] = useState(false);
	const { t } = useTranslation('translations');
	const { addDialog, addToast } = useFeedback();
	const QuestionApi = new BackofficeQuestionApiImpl();

	const submit = async (
		values: IBackofficeQuestionRequest,
		formikHelpers: FormikHelpers<IBackofficeQuestionRequest>,
		id?: string,
	) => {
		formikHelpers.setSubmitting(true);
		if (id) {
			try {
				const updateQuestionRes = await QuestionApi.editBackofficeQuestion(values, id);
				console.log(updateQuestionRes);
				addDialog({
					title: t('success'),
					message: '',
					error: false,
				});
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		} else {
			try {
				const updateQuestionRes = await QuestionApi.addBackofficeQuestion(values);
				console.log(updateQuestionRes);
				addDialog({
					title: t('success'),
					message: '',
					error: false,
				});
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		}
	};

	const getQuestionById = async (id: string) => {
		setQuestionState({ error: '', loading: true });
		try {
			const getQuestionRes = await QuestionApi.getQuestionById(id);
			setQuestionById(getQuestionRes);
			setFormValues({
				description: getQuestionRes.description,
				stepOrder: getQuestionRes.stepOrder,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const getAnswersByQuestionId = async (id: string | undefined) => {
		setQuestionState({ error: '', loading: true });
		try {
			const resp = await QuestionApi.getAnswersBByQuestionId(id);
			setAnswers(resp);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const submitAnswer = async (
		values: IAnswerReq,
		formikHelpers: FormikHelpers<IAnswerReq>,
		id?: string,
	) => {
		formikHelpers.setSubmitting(true);
		if (values.id) {
			try {
				await QuestionApi.updateAnswer(id ?? '', values);
				getAnswersByQuestionId(id);
				setAnswer({ text: '' });
				formikHelpers.resetForm();
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		} else {
			try {
				await QuestionApi.addAnswerToQuestion(id ?? '', values);
				getAnswersByQuestionId(id);
				setAnswer({ text: '' });
				formikHelpers.resetForm();
			} catch (error: any) {
				addDialog({
					title: error.title,
					message: error.message,
					error: true,
				});
			} finally {
				formikHelpers.setSubmitting(false);
			}
		}
	};

	const deleteAnswerById = async (answerId: number, questionId: number) => {
		try {
			const resp = await QuestionApi.deleteAnswer(answerId, questionId);
			getAnswersByQuestionId(questionId.toString());
			addToast({
				message: resp.message || t('deleted_successfully'),
				error: false,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const getQuestions = async () => {
		setQuestionState({ error: '', loading: true });
		try {
			const questionRes = await QuestionApi.getBackofficeQuestions();
			setQuestions(questionRes);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
			setQuestionState({ error: error.message, loading: true });
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const deleteQuestion = async (id: number) => {
		setQuestionState({ error: '', loading: true });
		try {
			const deleteQuestionRes = await QuestionApi.deleteQuestionById(id);
			getQuestions();
			addDialog({
				title: t('success'),
				message: deleteQuestionRes.message,
				error: false,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setQuestionState({ error: '', loading: false });
		}
	};

	const addAnswerRule = async (questionId: string | undefined, answerId: number) => {
		setAnswerRuleLoading(true);
		try {
			const resp = await QuestionApi.addAnswerRule(questionId, answerId, answrRule);
			addToast({
				message: resp.message,
				error: false,
			});
			getAnswersByQuestionId(questionId);
			return true;
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
			throw error;
		} finally {
			setAnswerRuleLoading(false);
		}
	};

	const deleteAnswerRule = async (questionId: string | undefined, answerId: number, ruleId: number) => {
		setAnswerRuleLoading(true);
		try {
			const resp = await QuestionApi.deleteAnswerRule(questionId, answerId, ruleId);
			addToast({
				message: resp.message || t('success'),
				error: false,
			});
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
			throw error;
		} finally {
			setAnswerRuleLoading(false);
		}
	};

	const value = useMemo(() => ({
		formValues,
		setFormValues,
		questionState,
		submit,
		getQuestions,
		questions,
		deleteQuestion,
		getQuestionById,
		questionById,
		answer,
		setAnswer,
		submitAnswer,
		deleteAnswerById,
		answrRule,
		setAnswerRule,
		answerRuleLoading,
		addAnswerRule,
		getAnswersByQuestionId,
		answers,
		deleteAnswerRule,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [formValues, questionState, questions, answer, answrRule, answerRuleLoading, answers]);

	return (
		<BackofficeQuestionContext.Provider value={value}>
			{children}
		</BackofficeQuestionContext.Provider>
	);
};

export function useBackofficeQuestions() {
	return useContext(BackofficeQuestionContext);
}
