/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Card,
	DialogContent,
	DialogTitle,
	ListItemProps,
	ListItemButton,
	useTheme,
	SvgIcon,
	Badge,
	Drawer,
} from '@mui/material';
import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
	Link as RouterLink, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppLinks from 'assets/applinks.routes';
import {
	Cart,
} from 'assets/icons';
import BodyText from '../body-text/BodyText';
import Dimens from 'assets/dimens';
import useCart from '../../providers/cart.provider';

interface Props {
	open: boolean;
	handleClose: () => void
}

interface ListItemLinkProps extends ListItemProps {
  to: string;
  icon?: React.ReactNode;
  title: string
}

const ListItemLink = (props: ListItemLinkProps) => {
	const location = useLocation();
	const [pathname, setPathName] = React.useState(location.pathname);
	const { palette } = useTheme();

	React.useEffect(() => {
		setPathName(location.pathname);
	}, [location]);

	const {
		to, icon, title, ...other
	} = props;

	return (
		<ListItemButton component={RouterLink as any} to={to} {...other} sx={{ ml: 0, pl: 0, mt: 2 }}>
			<BodyText
				sx={{
					color: palette.textMain.main,
					fontWeight: pathname === to ? Dimens.FONT_WEIGHT_BOLDER : Dimens.FONT_WEIGHT_NORMAL,
					fontSize: Dimens.BODY_TEXT_MS,
					mr: 2,
				}}

			>
				{title}
			</BodyText>
			{icon}
		</ListItemButton>
	);
};

const NavMenuMobile: React.FC<Props> = (props) => {
	const { open, handleClose } = props;
	const { t } = useTranslation('translations');
	const { cartProducts } = useCart();

	return (
		<Drawer open={open} onClose={handleClose}>
			<DialogTitle boxShadow={0}>
				<SvgIcon onClick={handleClose}>
					<ArrowBack />
				</SvgIcon>
			</DialogTitle>
			<DialogContent>
				<Card elevation={0} sx={{ p: 2, mt: 3 }}>
					<ListItemLink onClick={handleClose} to={AppLinks.LANDING} title={t('home')} />
					<ListItemLink onClick={handleClose} to={AppLinks.STORE} title={t('store')} />
					<ListItemLink onClick={handleClose} to={AppLinks.MOBILE_APP} title={t('app').toUpperCase()} />
					<ListItemLink onClick={handleClose} to={AppLinks.COMMUNITY} title={t('community')} />
					<ListItemLink onClick={handleClose} to={AppLinks.CONTACT_US} title={t('about_us')} />
					<ListItemLink onClick={handleClose} to={AppLinks.CONTACT_US} title={t('contact')} />
					<ListItemLink
						onClick={handleClose}
						to={AppLinks.CHECKOUT_PAGE}
						title={t('shopping_cart')}
						icon={(
							<Badge
								badgeContent={cartProducts.length}
								color="primary"
							>
								<SvgIcon><Cart /></SvgIcon>
							</Badge>
						)}
					/>
				</Card>
			</DialogContent>
		</Drawer>
	);
};

export default NavMenuMobile;
