import React from 'react';
import CheckoutSuccessPage from './CheckoutSuccessPage';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';
import OtherStories from './OtherStories';

const CheckoutSuccess = () => (
	<main>
		<CommunityProvier>
			<CheckoutSuccessPage />
			<OtherStories />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default CheckoutSuccess;
