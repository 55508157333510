export interface IChargeEventType {
  id: number;
  name: string;
}

export interface IChargeEventItem {
  chargeEventID: number;
  type: IChargeEventType;
  dateTimeUtc: string;
  dateTimeLocal: string;
  energy: number;
  energyCost: number;
  planRatePrice: number;
  rateType: string;
}

export interface IChargeEventData {
  itemsTotal: number;
  pageNumber: number;
  pageSize: number;
  pagesTotal: number;
  pageItemsTotal: number;
  pageFirstItemIndex: number;
  pageLastItemIndex: number;
  pageItems: IChargeEventItem[];
}

export enum ChargeEventTypeEnum {
	INVALID,
	START,
	END,
	PERIOD
}
