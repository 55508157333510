/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { reqGet, reqPost, reqPut } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { ClientAreaLoginParams, IUser } from '../entities/user';
import { IAlert } from '../entities/alert';

export class LoginApiImpl {
	async login(params: ClientAreaLoginParams): Promise<IUser> {
		try {
			const resp = await reqPut({
				url: Endpoints.LOGIN,
				data: {
					...params,
					osUniqueHash: 'dskadkjshakjdaks',
					deviceTypeID: 1,
					appID: 1,
					platformID: 1,
				},
			});
			const data = resp.data as ApiResponse<IUser>;
			return data.data;
		} catch (error: any) {
			if (error.response) {
				const data = error.response.data as ApiResponse<void>;
				throw new Error(data.message);
			}
			throw new Error('An Error Occurred');
		}
	}

	async getAlerts(): Promise<IAlert[]> {
		try {
			const resp = await reqGet({
				url: Endpoints.ALERTS,
			});
			const data = resp.data as ApiResponse<IAlert[]>;
			return data.data;
		} catch (error: any) {
			if (error.response) {
				const data = error.response.data as ApiResponse<void>;
				throw new Error(data.message);
			}
			throw new Error('An Error Occurred');
		}
	}

	async forgotPassword(email: string): Promise<string> {
		try {
			const resp = await reqPost({
				url: Endpoints.FORGOT_PASSWORD,
				data: {
					email,
				},
			});
			const data = resp.data as ApiResponse<void>;
			return data.message;
		} catch (error: any) {
			if (error.response) {
				const data = error.response.data as ApiResponse<void>;
				throw new Error(data.message);
			}
			throw new Error('An Error Occurred');
		}
	}
}
