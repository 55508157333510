/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
import { ClientAreaLoginParams, IUser } from 'features/client-login/entities/user';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import AppLinks from 'assets/applinks.routes';
import { useNavigate } from 'react-router-dom';
import { IAlert } from '../../entities/alert';
import { LoginApiImpl } from '../../data/login-api-impl';

interface AuthContextProps {
   user?: IUser | null;
   setCurrentUser: (user: IUser | null) => void;
   loading: boolean;
   login: (params: ClientAreaLoginParams) => void;
   error: string;
   getAlerts: () => void;
   alerts: IAlert[];
   logoutUser: () => void;
   forgotPassword: (email:string) => void;
   forgotPasswordResp: string
}

const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const tempUser = LocalStorageWrapper.get<IUser>(LocalStorageKeys.USER);
	const [user, setUser] = useState<IUser | null>(tempUser);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [alerts, setAlerts] = useState([] as IAlert[]);
	const [forgotPasswordResp, setForgotPasswordResp] = useState('');
	const navigate = useNavigate();

	const LoginApi = new LoginApiImpl();
	const getAlerts = async () => {
		// add try catch block
		setLoading(true);
		try {
			const getAlertsResult = await LoginApi.getAlerts();
			setAlerts(getAlertsResult);
		} catch (error) {
			setError((error as Error).message);
		} finally {
			setLoading(false);
		}
	};

	const login = async (params: ClientAreaLoginParams) => {
		setLoading(true);
		try {
			const loginResult = await LoginApi.login(params);
			setUser(loginResult);
			LocalStorageWrapper.set(LocalStorageKeys.USER, loginResult);
			getAlerts();
			navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
		} catch (error) {
			setError((error as Error).message);
		} finally {
			setLoading(false);
		}
	};

	const forgotPassword = async (email: string) => {
		setLoading(true);
		try {
			const forgotPasswordResult = await LoginApi.forgotPassword(email);
			setForgotPasswordResp(forgotPasswordResult);
		} catch (error) {
			setError((error as Error).message);
		} finally {
			setLoading(false);
		}
	};

	const setCurrentUser = (currentUser: IUser | null) => {
		setUser(currentUser);
	};

	const logoutUser = () => {
		LocalStorageWrapper.remove(LocalStorageKeys.USER);
		navigate(AppLinks.LANDING);
	};

	const value = useMemo(() => ({
		user,
		setCurrentUser,
		loading,
		error,
		login,
		getAlerts,
		alerts,
		logoutUser,
		forgotPasswordResp,
		forgotPassword,
	}), [user, loading, error, alerts, forgotPasswordResp]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
