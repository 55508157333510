/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useUseProductValidation = () => {
	const { t } = useTranslation('translations');

	const productSchema = yup.object({
		name: yup.string().required(t('required')),
		description: yup.string().required(t('required')),
		price: yup.string().min(2).required(t('required')),
		storageNote: yup.string().required(t('required')),
		howItWorks: yup.string().required(t('required')),
		categoryId: yup.number().min(1, t('required')).required(t('required')),
		warrantyMonths: yup.number().min(0).required(t('required')),
		attachments: yup.array(yup.object().shape({
			attachmentId: yup.string().required(t('required')),
		})),
		installationSteps: yup.array(yup.object().shape({
			header: yup.string().required(t('required')),
			description: yup.string().required(t('required')),
		})),
		specifications: yup.array(yup.object().shape({
			header: yup.string().required(t('required')),
			description: yup.string().required(t('required')),
		})),
	});

	return { productSchema };
};
