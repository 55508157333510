import { Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import {
	authenticationIcon, dataCollectionIcon, monitoringIcon, optimizationIcon,
	recognitionIcon, simulationIcon,
} from 'assets/icons';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import FeatureItem from '../components/FeatureItem';

const IokeeFeatures = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const isLg = useMediaQuery(theme.breakpoints.up('lg'));
	return (
		<Stack
			sx={{
				justifyContent: 'center',
				mt: { lg: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				pr: { lg: 0, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { lg: 0, xs: Dimens.PAGE_PADDING_MOBILE },
				overflowX: 'scroll',
				'&::-webkit-scrollbar': { display: 'none' },
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
			}}
			direction="column"
			gap={6}
		>
			<Stack direction="row" spacing={3} sx={{ width: { lg: '120%', xs: '100%' }, ml: { lg: '-7%', xs: 0 } }}>
				{isLg && <FeatureItem showEmpty />}
				<FeatureItem
					title={t('accurate_data_collection')}
					icon={dataCollectionIcon}
					description={t('accurate_data_collection_info')}
				/>
				<FeatureItem
					title={t('usage_insight')}
					icon={simulationIcon}
					description={t('usage_insight_info')}
				/>
				{isLg && <FeatureItem showEmpty />}
				{isLg && <FeatureItem showEmpty />}
			</Stack>
			<Stack direction="row" spacing={3} sx={{ width: { lg: '120%', xs: '100%' }, ml: { lg: '-1%', xs: 0 } }}>
				{isLg && <FeatureItem showEmpty />}
				{/* {isMd && <FeatureItem showEmpty />} */}
				<FeatureItem
					title={t('real_time_monitoring')}
					icon={monitoringIcon}
					description={t('real_time_monitoring_info')}
				/>
				<FeatureItem
					title={t('authentication_to_prevent')}
					icon={authenticationIcon}
					description={t('authentication_to_prevent_info')}
				/>
				{isLg && <FeatureItem showEmpty />}
				{isLg && <FeatureItem showEmpty />}
			</Stack>
			<Stack direction="row" spacing={3} sx={{ width: { lg: '120%', xs: '100%' }, ml: { lg: '-7%', xs: 0 } }}>
				{isLg && <FeatureItem showEmpty />}
				{isLg && <FeatureItem showEmpty />}
				<FeatureItem
					title={t('energy_leaks_recognition')}
					icon={recognitionIcon}
					description={t('energy_leaks_recognition_info')}
				/>
				<FeatureItem
					title={t('energy_optimization_recommendation')}
					icon={optimizationIcon}
					description={t('energy_optimization_recommendation_info')}
				/>
				{isLg && <FeatureItem showEmpty />}
			</Stack>
		</Stack>
	);
};

export default IokeeFeatures;
