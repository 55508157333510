/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
	CSSObject, Theme, styled,
	useTheme,
} from '@mui/material/styles';
import React from 'react';
import {
	Avatar,
	Badge,
	IconButton,
	Stack,
	SvgIconTypeMap,
	Toolbar,
	Typography,
	useMediaQuery,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';
import {
	ArrowBack, Dashboard, Logout, QuestionAnswer,
} from '@mui/icons-material';
import { useBackofficeAuth } from './features/auth/presentation/provider/auth.provider';
import { logo } from '../../assets/icons';
import LocalStorageWrapper, { LocalStorageKeys } from '../../utils/storage.utils';
import {
	CommunityRequests,
	Orders,
	ProductCategories,
	Products,
} from './assets';
import ErrorDialog from 'common/presentation/components/error-dailog/ErrorDailog';
import Toast from 'common/presentation/components/toast/Toast';
import { LanguageSelector } from 'common/presentation/components';
import { useBackofficeOrders } from './features/providers/order.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	background: theme.palette.background.paper,
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		// marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

interface DrawerMenuItemProps {
	open?: boolean;
	label: string,
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
		title?: string | undefined;
	}> | OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string; }
	link: string,
	badgeCount?: number
	onClick: () => void;
}

const DrawerMenuItem = ({
	open, label, icon: ItemIcon, link, onClick, badgeCount,
}: DrawerMenuItemProps) => {
	const selected = !!useMatch(link);
	const navigate = useNavigate();
	const theme = useTheme();
	return (
		<ListItem
			disablePadding
			sx={{ mb: 1 }}
			onDoubleClick={() => {
				if (selected) {
					onClick.call(0);
				}
			}}
			onClick={() => {
				navigate(link);
			}}
		>
			<ListItemButton
				sx={{
					minHeight: 48,
					justifyContent: open ? 'initial' : 'center',
					backgroundColor: selected ? theme.palette.primary.main : 'none',
					color: selected ? theme.palette.primary.contrastText : 'none',
					borderRadius: '0 31px 31px 0',
					pt: 0,
					pb: 0,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 1 : 'auto',
						justifyContent: 'center',
					}}
				>
					{ItemIcon && <Badge badgeContent={badgeCount} color="error"><ItemIcon height={20} width={20} /></Badge>}
				</ListItemIcon>
				<ListItemText
					primary={(
						<Typography
							sx={{
								opacity: open ? 1 : 0,
								// fontWeight: selected ? Dimens.FONT_BOLD : Dimens.FONT_THIN,
								color: theme.palette.textMain.main,
								fontSize: selected
									? Dimens.BODY_TEXT_S : Dimens.BODY_TEXT_M_MOBILE,
							}}
						>
							{label}
						</Typography>
					)}
				/>

				{open && selected && (
					<IconButton
						edge="end"
					>
						<ArrowBack height={30} width={30} />
					</IconButton>
				)}
			</ListItemButton>
		</ListItem>
	);
};

export const BackOfficeMain = () => {
	// const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { backofficeUser } = useBackofficeAuth();
	const theme = useTheme();
	const isSm = useMediaQuery(theme.breakpoints.down('sm'));
	const { newOrdersCount, getNewOrdersNotification } = useBackofficeOrders();

	useEffectCustom(() => {
		getNewOrdersNotification();
	}, []);

	useEffectCustom(() => {
		if (isSm) {
			setOpen(false);
		}
	}, [isSm]);

	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	return (
		<Box sx={{ display: 'flex', backgroundColor: theme.palette.background.default }}>
			<CssBaseline />
			<ErrorDialog />
			<Toast />
			<AppBarStyled position="fixed" open={open} elevation={1}>
				<Toolbar>
					<Stack direction="row" flex={1}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={() => navigate(AppLinks.BACKOFFICE_DASHBOARD)}
							sx={{
								...(open && { display: 'none' }),
								'&.MuiButtonBase-root:hover': {
									bgcolor: 'transparent',
								},
							}}
						>
							<img src={logo} alt="iokee" />
						</IconButton>

						<Stack direction="column" sx={{ ml: open ? 0 : 2, mt: 2, mb: 2 }}>
							<Typography sx={{ mt: 1 }} color="#8F8F8F">
								{t('welcome_to_backoffice')}
							</Typography>
						</Stack>
						<Box sx={{ flexGrow: 1 }} />
						<Stack alignItems="center" justifyContent="center" sx={{ mr: 3 }}>
							<LanguageSelector />
						</Stack>
						<Box sx={{ alignSelf: 'center' }} title={backofficeUser?.fullname}>
							<Avatar src={backofficeUser?.fullname} />
						</Box>
					</Stack>
				</Toolbar>
			</AppBarStyled>

			<Drawer variant="permanent" open={open}>
				<DrawerHeader sx={{ pt: 2 }}>
					{open ? <img src={logo} alt="Iokee" /> : <img src={logo} alt="Iokee" />}
				</DrawerHeader>
				<Divider />
				<List sx={{ mr: open ? 2 : 0.5, mt: 4 }}>
					<DrawerMenuItem
						label={t('product_categories')}
						icon={ProductCategories}
						link={AppLinks.BACKOFFICE_DASHBOARD}
						open={open}
						onClick={handleDrawerToggle}
					/>
					<DrawerMenuItem
						label={t('products')}
						icon={Products}
						link={AppLinks.BACKOFFICE_PRODUCTS}
						open={open}
						onClick={handleDrawerToggle}
					/>
					<DrawerMenuItem
						label={t('orders')}
						icon={Orders}
						badgeCount={newOrdersCount}
						link={AppLinks.BACKOFFICE_ORDERS}
						open={open}
						onClick={handleDrawerToggle}
					/>
					<DrawerMenuItem
						label={t('community_requests')}
						icon={CommunityRequests}
						link={AppLinks.BACKOFFICE_COMMUNITY_REQUESTS}
						open={open}
						onClick={handleDrawerToggle}
					/>
					<DrawerMenuItem
						label={t('questions')}
						icon={QuestionAnswer}
						link={AppLinks.BACKOFFICE_QUESTIONS}
						open={open}
						onClick={handleDrawerToggle}
					/>
				</List>

				<Box sx={{ flexGrow: 1 }} />
				<List sx={{ mb: 2, mr: open ? 1 : 0.5 }}>
					<ListItem
						disablePadding
					>
						<ListItemButton
							onClick={() => {
								LocalStorageWrapper.remove(LocalStorageKeys.BACKOFFICE_USER);
								navigate(AppLinks.BACKOFFICE_LOGIN);
							}}
							sx={{
								minHeight: 48,
								justifyContent: open ? 'initial' : 'center',
								borderRadius: '0 31px 31px 0',
								pt: 0,
								pb: 0,
							}}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 1 : 'auto',
									justifyContent: 'center',
								}}
							>
								<Logout height={40} width={40} />
							</ListItemIcon>
							<ListItemText
								primary={(
									<Typography
										sx={{
											opacity: open ? 1 : 0,
											color: theme.palette.textMain.main,
											fontSize: Dimens.BODY_TEXT_S,
										}}
									>
										{t('logout')}
									</Typography>
								)}
							/>
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>

			<Box
				component="main"
				sx={{
					p: 3,
					pt: 7,
					flex: 1,
					boxSizing: 'border-box',
					backgroundColor: theme.palette.background.default,
					minHeight: '100vh',
				}}
			>
				<DrawerHeader />
				<Outlet />
			</Box>
		</Box>
	);
};
