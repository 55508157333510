export const getShowWhatsInBox = (productId: number) => {
	let isVisible = false;
	switch (productId) {
	case 5:
	case 16:
	case 17:
	case 18:
	case 19:
		isVisible = true;
		break;
	default:
		isVisible = false;
		break;
	}

	return isVisible;
};
