import { FormikHelpers } from 'formik';
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { validateEmail, validatePassword } from 'utils/validation';
import { AuthResponse, LoginParams } from '../../domain/entities/login';
import { AuthRepositoryImpl } from '../../data/repository/auth-repository-impl';
import { Login } from '../../domain/usecases/Login';
import useFeedback from 'common/presentation/providers/feedback.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

type AuthContextProps = {
	initialValues: LoginParams;
	submit: (values: LoginParams, formikHelpers: FormikHelpers<LoginParams>) => void;
	validate: (values: LoginParams) => Partial<LoginParams>;
	backofficeUser: AuthResponse | null
}

const BackofficeAuthContext = createContext({} as AuthContextProps);

export const BackofficeAuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [initialValues] = useState({
		username: '',
		password: '',
	});
	const mUser = LocalStorageWrapper.get(LocalStorageKeys.BACKOFFICE_USER) as AuthResponse;
	const [backofficeUser, setBackofficeUser] = useState<AuthResponse | null>(mUser);
	const { t } = useTranslation('translations');
	const authRepository = new AuthRepositoryImpl();
	const { addDialog } = useFeedback();
	const navigate = useNavigate();

	const validate = (values: LoginParams): Partial<LoginParams> => {
		const errors = {} as Partial<LoginParams>;
		if (!values.username) {
			errors.username = t('required');
		}
		if (values.username && !validateEmail(values.username)) {
			errors.username = t('email_error');
		}
		if (!values.password) {
			errors.password = t('required');
		}
		if (!validatePassword(values.password)) {
			errors.password = t('password_error');
		}
		return errors;
	};

	const submit = async (values: LoginParams, formikHelpers: FormikHelpers<LoginParams>) => {
		formikHelpers.setSubmitting(true);
		const authUseCase = new Login(authRepository);
		const response = await authUseCase.call(values);
		if (response.isRight()) {
			setBackofficeUser(response.value);
			LocalStorageWrapper.set(LocalStorageKeys.BACKOFFICE_USER, response.value);
			formikHelpers.setSubmitting(false);
			navigate(AppLinks.BACKOFFICE_DASHBOARD);
		} else {
			addDialog({
				error: true,
				title: response.error,
				message: response.error,
			});
			formikHelpers.setSubmitting(false);
		}
	};

	const value = useMemo(() => ({
		initialValues,
		validate,
		submit,
		backofficeUser,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [initialValues, backofficeUser]);
	return <BackofficeAuthContext.Provider value={value}>{children}</BackofficeAuthContext.Provider>;
};

export function useBackofficeAuth() {
	return useContext(BackofficeAuthContext);
}
