import { loginImage } from 'assets/images';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { DefaultButton } from 'common/presentation/components';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useTranslation } from 'react-i18next';

const LoginImageView = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');

	const handleGoToWebsite = () => {
		navigate(AppLinks.LANDING);
	};

	return (
		<Box sx={{
			position: 'relative',
			width: { md: '60%', xs: '100%' },
			backgroundImage: `url(${loginImage})`,
			height: { md: '88vh', xs: '50vh' },
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% 100%',
			mt: { md: '24px', xs: '0px' },
			mr: Dimens.FOOTER_PADDING_VERTICAL,
			borderRadius: Dimens.CLIENT_AREA_LOGIN_IMAGE_BORDER_RADIUS,
		}}
		>
			<Box sx={{ position: 'absolute', top: 50, right: 100 }}>
				<DefaultButton
					disableRipple
					sx={{
						bgcolor: theme.palette.background.default,
						color: theme.palette.textMain.main,
						borderRadius: '50px',
						'&:hover': {
							bgcolor: theme.palette.background.default,
						},
					}}
					onClick={handleGoToWebsite}
				>
					{t('return_to_website')}
				</DefaultButton>
			</Box>
		</Box>
	);
};

export default LoginImageView;
