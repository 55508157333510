import { BodyText } from 'common/presentation/components';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormTabs } from 'features/backoffice/common/presentation/providers/tab-provider';
// import FormTabHeader from 'features/backoffice/common/presentation/form-tab-header/FormTabHeader';
import BackofficeAddEditProductCategoriesPage from './BackofficeAddEditProductCategoriesPage';
import BackofficeAddEditProductCategoriesTranslatedForm from './BackofficeAddEditProductCategoriesTranslatedForm';

const BackofficeAddEditProductCategories = () => {
	const { t } = useTranslation('translations');
	const { categoryId } = useParams();

	const { activeTab } = useFormTabs();

	return (
		<Box>
			<BodyText>
				{categoryId
					? t('edit_product_category')
					: t('add_product_category')}
			</BodyText>
			{/* <FormTabHeader /> */}
			{activeTab === 0 && <BackofficeAddEditProductCategoriesPage />}
			{activeTab === 1 && <BackofficeAddEditProductCategoriesTranslatedForm />}
		</Box>
	);
};

export default BackofficeAddEditProductCategories;
