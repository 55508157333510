/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
	IconButton,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import useEffectCustom from '../../hooks/useEffectCustom';
import Dimens from 'assets/dimens';

interface Props {
	images: string[]
}

const zoomLevel = 2;
const magnifierHeight = 250;
const magnifierWidth = 250;

const LinearImageCarousel: React.FC<Props> = (props) => {
	const { images } = props;
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isPaused, setIsPaused] = useState(false);
	const [showMagnifier, setShowMagnifier] = useState(false);
	const [[x, y], setXY] = useState([0, 0]);
	const [[imgHeight, imgWidth], setSize] = useState([0, 0]);
	const intervalRef = useRef<NodeJS.Timer | null>(null);

	const handleImageChange = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev === images.length - 1) {
				return 0;
			}
			mPrev += 1;
			return mPrev;
		});
	};

	const startAutoChange = () => {
		if (!intervalRef.current) {
			intervalRef.current = setInterval(() => {
				setSelectedIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
			}, 3000);
		}
	};

	const stopAutoChange = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	useEffectCustom(() => {
		if (!isPaused && images.length > 1) {
			startAutoChange();
		}
		return () => stopAutoChange();
	}, [isPaused, images.length]);

	const onImageClick = (index: number) => {
		setSelectedIndex(index);
		setIsPaused(true);
		stopAutoChange();
	};

	const onMainImageClick = () => {
		setIsPaused(true);
		stopAutoChange();
	};

	const handleImageChangeBackwards = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev === 0) {
				return images.length - 1;
			}
			mPrev -= 1;
			return mPrev;
		});
	};

	return (
		<Stack>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent={images.length > 1 ? 'space-between' : 'center'}
			>
				{images.length > 1 && (
					<IconButton
						color="inherit"
						sx={{
							border: `1px solid ${theme.palette.textMain.main}`,
							'&:hover': {
								bgcolor: theme.palette.primary.main,
							},
						}}
						onClick={() => handleImageChangeBackwards()}
					>
						<ArrowBack />
					</IconButton>
				)}
				<div
					style={{
						position: 'relative',
						width: isMd ? '500px' : '250px',
						height: isMd ? '500px' : '250px',
					}}
				>
					<img
						src={images[selectedIndex]}
						onClick={onMainImageClick}
						onMouseEnter={(e) => {
							const elem = e.currentTarget;
							const { width, height } = elem.getBoundingClientRect();
							setSize([width, height]);
							setShowMagnifier(true);
						}}
						onMouseLeave={() => {
							setShowMagnifier(false);
						}}
						alt="Watch"
						style={{
							width: isMd ? '500px' : '250px',
							height: isMd ? '500px' : '250px',
							objectFit: 'scale-down',
						}}
						onMouseMove={(e) => {
							const elem = e.currentTarget;
							const { top, left } = elem.getBoundingClientRect();
							const mX = e.pageX - left - window.pageXOffset;
							const mY = e.pageY - top - window.pageYOffset;
							setXY([mX, mY]);
						}}
					/>
					<div
						style={{
							display: showMagnifier ? '' : 'none',
							position: 'absolute',
							pointerEvents: 'none',
							height: `${magnifierHeight}px`,
							width: `${magnifierWidth}px`,
							top: `${(y) / 2}px`,
							left: `${(x) / 2}px`,
							opacity: '0.9',
							border: `1px solid ${theme.palette.primary.main}`,
							backgroundColor: theme.palette.background.paper,
							backgroundImage: `url('${images[selectedIndex]}')`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: `${imgWidth * zoomLevel}px ${
								imgHeight * zoomLevel
							}px`,
							backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
							backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
						}}
					/>
				</div>
				{images.length > 1 && (
					<IconButton
						color="inherit"
						sx={{
							border: `1px solid ${theme.palette.textMain.main}`,
							'&:hover': {
								bgcolor: theme.palette.primary.main,
							},
						}}
						onClick={() => handleImageChange()}
					>
						<ArrowForward />
					</IconButton>
				)}
			</Stack>
			<Stack
				direction="row"
				spacing={2}
				justifyContent={{
					md: 'center',
					lg: 'center',
					xl: 'center',
					sm: 'flex-start',
					xs: 'flex-start',
				}}
				sx={{
					display: { md: 'flex', xs: 'none', sm: 'none' },
					width: '100%',
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					zindex: 1,
					mt: '25px',
				}}
			>
				{images.map((el, index) => (
					<img
						key={el.toString()}
						src={el}
						alt="Watch"
						onClick={() => onImageClick(index)}
						style={{
							cursor: 'pointer',
							objectFit: 'scale-down',
							height: isMd ? '134px' : '95px',
							width: isMd ? '134px' : '95px',
							borderRadius: isMd
								? Dimens.SMALL_IMAGE_BORDER_RADIUS
								: Dimens.SMALL_IMAGE_BORDER_RADIUS_MOBILE,
							border: selectedIndex === index ? `2px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.textMain.main}`,
						}}
					/>
				))}
			</Stack>
		</Stack>
	);
};

export default LinearImageCarousel;
