import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';

interface DetailCardProps {
  label: string;
  value: string;
  subValue?: string;
}

const DetailCard: React.FC<DetailCardProps> = ({
	label, value, subValue,
}) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.paper,
				padding: Dimens.CLIENT_AREA_PAGE_PADDING_MOBILE,
				borderRadius: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				minWidth: Dimens.CIRCLE_RADIUS,
				height: '140px',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
				margin: '8px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>

				<BodyText
					sx={{
						color: theme.palette.primary.main,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						marginRight: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE,
						fontSize: Dimens.TITLE_TEXT_M_MOBILE,
					}}
				>
					{label}
				</BodyText>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}
				>
					<BodyText
						sx={{
							fontSize: Dimens.TITLE_TEXT_M_MOBILE,
							color: theme.palette.textMain.main,
							lineHeight: 1.2,
						}}
					>
						{value}
					</BodyText>
					{subValue && (
						<BodyText
							sx={{
								color: theme.palette.textMain.main,
								lineHeight: 1.2,
							}}
						>
							{subValue}
						</BodyText>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default DetailCard;
