import { BodyText } from 'common/presentation/components';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	styled,
	useTheme,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeOrders } from '../../providers/order.provider';
import { OrderRow } from './components/OrderRow';
import Autocomplete from 'features/backoffice/common/presentation/auto-complete/Autocomplete';
import { IOrder } from '../entities/order';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';

const BackofficeOrdersPage = () => {
	const { t } = useTranslation('translations');
	const {
		orders,
		changeStatus,
		getOrders,
		loading,
		currentPage,
		pageSize,
		setCurrentPage,
		setPageSize,
		params,
		setParams,
		requestParams,
	} = useBackofficeOrders();
	const theme = useTheme();
	const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null);
	const [showStatusChangeDialog, setShowStatusChangeDialog] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

	useEffectCustom(() => {
		getOrders();
	}, [pageSize, currentPage]);

	const ORDER_STATUSES = [
		{ label: t('new'), id: 1 },
		{ label: t('in_progress'), id: 2 },
		{ label: t('served'), id: 3 },
		{ label: t('rejected'), id: 4 },
	];

	const StyledTableRow = styled(TableRow)(() => ({
		padding: '34px',
		backgroundColor: theme.palette.action.hover,
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	const handleReset = () => {
		setParams({
			FullName: '',
			Email: '',
		});
		getOrders(true);
	};

	const handleChangeStatus = async () => {
		if (!selectedStatus || !selectedOrder) {
			return;
		}
		changeStatus(selectedOrder?.id, selectedStatus);
		setShowStatusChangeDialog(false);
		setSelectedStatus(null);
	};

	const onConfirmStatusChange = () => {
		handleChangeStatus();
	};

	return (
		<Box>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('orders')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
				{/* <Box sx={{ width: { md: '15%', xs: '40%' } }}>
				</Box> */}
			</Stack>
			<Stack
				justifyContent="space-between"
				sx={{
					bgcolor: theme.palette.background.paper,
					pt: 4,
					pb: 4,
					mt: 2,
					pl: 2,
					pr: 2,
				}}
			>
				<Grid container spacing={2}>
					<Grid item md={3} sm={12}>
						<BackofficeTextField
							label={t('name')}
							value={params?.FullName}
							onChange={(e) => setParams({ ...params, FullName: e.target.value })}
						/>
					</Grid>
					<Grid item md={3} sm={12}>
						<BackofficeTextField
							label={t('email')}
							value={params?.Email}
							onChange={(e) => setParams({ ...params, Email: e.target.value })}
						/>
					</Grid>
					<Grid item md={3} sm={12}>
						<Autocomplete
							options={ORDER_STATUSES}
							disabled={loading}
							defaultValue={ORDER_STATUSES.find((el) => el.id === params?.Status)}
							label={t('change_status')}
							placeholder={t('select_new_status')}
							getOptionLabel={(option) => option.label}
							renderOption={(props, option) => (
								<li {...props} key={option.id}>{option.label}</li>
							)}
							onChange={(_, option) => setParams({ ...params, Status: option?.id })}
						/>
					</Grid>
					<Grid item md={3} sm={12}>
						<Button
							variant="contained"
							disabled={!params?.Email && !params?.Status && !params?.FullName}
							onClick={() => getOrders()}
						>
							{t('search')}
						</Button>
						<Button
							color="info"
							variant="outlined"
							sx={{ ml: 2 }}
							disabled={!requestParams?.Email && !requestParams?.FullName && !requestParams?.Status}
							onClick={handleReset}
						>
							{t('reset')}
						</Button>
					</Grid>
				</Grid>
			</Stack>
			<Box
				sx={{
					width: '100%',
					display: 'table',
					tableLayout: 'fixed',
					bgcolor: theme.palette.background.paper,
				}}
				mt={6}
			>
				{loading && (
					<Stack alignItems="center">
						<CircularProgress />
					</Stack>
				)}
				<Table sx={{ minWidth: 1000 }} size="small" aria-label="a dense table">
					<TableHead sx={{ bgcolor: theme.palette.action.hover }}>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell align="right">{t('user_name')}</TableCell>
							<TableCell align="center">{t('email')}</TableCell>
							<TableCell align="right">{t('phone')}</TableCell>
							<TableCell align="right">{t('date')}</TableCell>
							<TableCell align="right">{t('action')}</TableCell>
							<TableCell align="right">{t('status')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orders?.data?.map((row) => (
							<OrderRow
								key={row.id}
								setRowSelected={setSelectedOrder}
								onStatusChangeClick={(order) => {
									setSelectedOrder(order);
									setShowStatusChangeDialog(true);
								}}
								rowSelected={selectedOrder}
								data={row}
							/>
						))}
						{orders?.data?.length === 0 && (
							<StyledTableRow
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="center" colSpan={6}>{t('no_orders')}</TableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={orders?.totalCount || 0}
					page={currentPage - 1}
					onPageChange={(_, newPage) => {
						setCurrentPage(newPage + 1);
					}}
					rowsPerPage={pageSize}
					onRowsPerPageChange={(e) => {
						setPageSize(parseInt(e.target.value, 10));
						setCurrentPage(1);
					}}
				/>
			</Box>
			<Dialog open={showStatusChangeDialog}>
				<DialogTitle>{t('change_order_status')}</DialogTitle>
				<DialogContent>
					<Box sx={{ pt: 2, pb: 2 }}>
						{selectedOrder && (
							<Autocomplete
								options={ORDER_STATUSES}
								disabled={loading}
								defaultValue={ORDER_STATUSES.find((el) => el.id === selectedOrder?.status)}
								label={t('change_status')}
								placeholder={t('select_new_status')}
								getOptionLabel={(option) => option.label}
								renderOption={(props, option) => (
									<li {...props} key={option.id}>{option.label}</li>
								)}
								onChange={(_, option) => setSelectedStatus(option?.id || null)}
							/>
						)}
					</Box>
					<DialogActions>
						<Button variant="contained" onClick={onConfirmStatusChange}>
							{t('save')}
						</Button>
						<Button color="error" variant="outlined" onClick={() => setShowStatusChangeDialog(false)}>
							{t('cancel')}
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default BackofficeOrdersPage;
