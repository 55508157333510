import React from 'react';
import StartSection from './StartSection';

const StartScreen = () => (
	<main>
		<StartSection />
	</main>
);

export default StartScreen;
