import {
	Box, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ClientAreaSidebar from './common/presentation/components/client-area-sidebar/ClientAreaSidebar';
import Dimens from './assets/dimens';
import LogoutButton from './common/presentation/components/logout-button/LogoutButton';
import { ClientAreaBreadCrumb } from './common';
import AppLinks from './assets/applinks.routes';

const ClientAreaAuthenticated = () => {
	const theme = useTheme();
	const isMd = useMediaQuery(() => theme.breakpoints.up('md'));
	const location = useLocation();

	return (
		<Stack
			direction={{ md: 'row', xs: 'column' }}
			sx={{
				mr: { md: Dimens.CLIENT_AREA_PAGE_MARGIN_RIGHT, xs: Dimens.PAGE_PADDING_MOBILE },
				ml: { md: Dimens.CLIENT_AREA_PAGE_MARGIN_RIGHT, xs: Dimens.PAGE_PADDING_MOBILE },
				pb: { xs: Dimens.PAGE_PADDING_MOBILE, md: 0 },
			}}
			columnGap={4}
		>
			<ClientAreaSidebar />
			<Box sx={{
				pl: { md: Dimens.CLIENT_AREA_PAGE_PADDING, xs: Dimens.CLIENT_AREA_PAGE_PADDING_MOBILE },
				pr: { md: Dimens.CLIENT_AREA_PAGE_PADDING, xs: Dimens.CLIENT_AREA_PAGE_PADDING_MOBILE },
				pt: {
					md: Dimens.CLIENT_AREA_PAGE_PADDING_VERTICAL,
					xs: Dimens.CLIENT_AREA_PAGE_PADDING_VERTICAL_MOBILE,
				},
				pb: {
					md: Dimens.CLIENT_AREA_PAGE_PADDING_VERTICAL,
					xs: Dimens.CLIENT_AREA_PAGE_PADDING_VERTICAL_MOBILE,
				},
				minHeight: '82vh',
				width: { md: '68%', xs: '100%' },
				bgcolor: theme.palette.background.paper,
				mt: Dimens.CLIENT_AREA_PADDING_BOTTOM,
				mb: Dimens.CLIENT_AREA_PADDING_BOTTOM,
				borderRadius: {
					md: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS,
					xs: Dimens.CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE,
				},
			}}
			>
				{(
					location.pathname !== AppLinks.CLIENT_AREA_DEVICE_LIST && isMd
				) && <ClientAreaBreadCrumb />}
				<Outlet />
			</Box>
			{!isMd && <LogoutButton />}
		</Stack>
	);
};

export default ClientAreaAuthenticated;
