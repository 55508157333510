import React from 'react';
import StoreHero from './StoreHero';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';
import { LandingProvider } from 'features/landing/presentation/provider/landing-provider';

const StorePage = () => (
	<main className="container">
		<LandingProvider>
			<CommunityProvier>
				<StoreHero />
				<CommunityForm />
			</CommunityProvier>
		</LandingProvider>
	</main>
);

export default StorePage;
