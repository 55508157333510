import React from 'react';
// import BuyProduct from './BuyProduct';
// import EnergyEfficiencyController from './EnergyEfficiencyController';
import EnergyEfficiencyControllerPhases from './EnergyEfficiencyControllerPhases';
import HowIokeeWorks from './HowIokeeWorks';
import IokeeFeatures from './IokeeFeatures';
import Setup from './Setup';
import TechnicalSpecification from './TechnicalSpecification';
import { CommunityForm } from 'common/presentation/components';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import PerfectMatch from './PerfectMatch';
import MobileApp from './MobileApp';
import { useMediaQuery, useTheme } from '@mui/material';
import { useStoreProducts } from '../provider/product-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useParams } from 'react-router-dom';
import withProductContext from '..';

const ProductDetailPage = () => {
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const { productId } = useParams();
	const { getProductById } = useStoreProducts();

	useEffectCustom(() => {
		if (productId) {
			getProductById(productId);
		}
	}, []);

	return (
		<main>
			<CommunityProvier>
				<EnergyEfficiencyControllerPhases />
				<TechnicalSpecification />
				<HowIokeeWorks />
				{isMd && <PerfectMatch />}
				<IokeeFeatures />
				{!isMd && <PerfectMatch />}
				<MobileApp />
				<Setup />
				<CommunityForm />
			</CommunityProvier>
		</main>
	);
};

export default withProductContext(ProductDetailPage);
