import {
	BodyText, DefaultButton, DefaultDropdown, DeviceCard,
} from 'common/presentation/components';
import {
	Box, CircularProgress, Stack, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useAuth from 'features/client-login/presentation/provider/auth.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import useDevices from './provider/device.provider';
import { analyzerIcon, wallboxIcon } from 'assets/icons';
import { IDevice } from '../entities/device';
import { IReportDate } from '../entities/report';
import DeviceSelectForReportModal from './components/DeviceSelectForReportModal';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const DeviceListPage = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { user } = useAuth();
	const [showReportModal, setShowReportModal] = useState(false);

	const handleDeviceClick = (device: IDevice) => {
		LocalStorageWrapper.set(LocalStorageKeys.DEVICE, device);
		navigate(AppLinks.CLIENT_AREA_DEVICE_DETAIL);
	};

	const handleWallboxClick = (device: IDevice) => {
		LocalStorageWrapper.set(LocalStorageKeys.DEVICE, device);
		navigate(AppLinks.CLIENT_AREA_WALLBOX_HISTORY);
	};

	const handleClickReport = () => {
		setShowReportModal(true);
	};

	const {
		getUserProducts,
		loading,
		error,
		// hub,
		wallBox,
		analyzer,
		reportLoading,
		handleClickShowReport,
		getProductTags,
		productTags,
		selectedTag,
		setSelectedTag,
	} = useDevices();

	useEffectCustom(() => {
		getUserProducts();
	}, []);

	useEffectCustom(() => {
		getProductTags();
	}, []);

	const handleExportClick = (ids: number[], dates: IReportDate) => {
		handleClickShowReport(ids, dates).then((res) => {
			const url = URL.createObjectURL(res);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${t('wallbox')} - ${t('report')} - ${new Date().getTime()}.xlsx`;
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	};

	return (
		<Box>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
			}}
			>
				{t('client_area_welcome_message').format(user?.name)}
			</BodyText>
			<BodyText sx={{
				fontSize: {
					md: Dimens.BODY_TEXT_M,
					xs: Dimens.BODY_TEXT_M_MOBILE,
				},
				mt: 1,
				color: theme.palette.textLight.main,
			}}
			>
				{t('monitor_energy_consumption')}
			</BodyText>
			<BodyText sx={{
				fontSize: {
					md: Dimens.BODY_TEXT_M,
					xs: Dimens.BODY_TEXT_M_MOBILE,
				},
				color: theme.palette.textLight.main,
			}}
			>
				{t('get_access_to_all_reports')}
			</BodyText>
			{productTags && productTags.length !== 0 && (
				<Stack direction="row" alignItems="center" sx={{ mt: { md: 6, sm: 2 } }}>
					<BodyText
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
						}}
					>
						{t('filter_by_tags')}
						:
						{' '}
					</BodyText>
					<DefaultDropdown
						options={productTags}
						renderOption={(option) => option}
						renderOptionLabel={(option) => option}
						value={selectedTag}
						handleSelect={(value) => setSelectedTag(value)}
					/>
				</Stack>
			)}
			<Stack direction={{ md: 'row', xs: 'column' }} sx={{ mt: 2 }}>
				<BodyText sx={{
					fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					{t('your_analyzers').toUpperCase()}
				</BodyText>
				<BodyText sx={{
					fontSize: {
						md: Dimens.BODY_TEXT_M,
						xs: Dimens.BODY_TEXT_M_MOBILE,
					},
					ml: { md: 2, xs: 0 },
					fontStyle: 'italic',
				}}
				>
					{t('choose_analyzer_to_consult')}
				</BodyText>
			</Stack>
			<Stack direction="row" useFlexGap flexWrap="wrap" spacing={3} columnGap={3} sx={{ mt: 2.5 }}>
				{loading && <CircularProgress />}
				{error && <BodyText>{error}</BodyText>}
				{analyzer && analyzer.map((el) => (
					<DeviceCard
						key={el.userProductID}
						title={el.name}
						deviceName={`${el?.board?.productCode}${el?.board?.serialNumber}`}
						address={el?.address}
						onClick={() => handleDeviceClick(el)}
						deviceImage={analyzerIcon}
						tags={el.tags}
					/>
				))}
			</Stack>
			{wallBox?.length !== 0 && (
				<Stack
					direction={{ md: 'row', xs: 'column' }}
					spacing={2}
					alignItems="center"
					sx={{ mt: { md: 10, xs: 3 } }}
				>
					<BodyText sx={{
						fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					>
						{t('your_wallboxes').toUpperCase()}
					</BodyText>
					<DefaultButton
						onClick={handleClickReport}
						variant="contained"
					>
						{t('report')}
					</DefaultButton>
				</Stack>
			)}
			<Stack direction="row" useFlexGap flexWrap="wrap" spacing={3} columnGap={3} sx={{ mt: 2.5 }}>
				{wallBox && wallBox.map((el) => (
					<DeviceCard
						key={el.userProductID}
						title={el.name}
						deviceName={`${el?.board?.productCode}${el?.board?.serialNumber}`}
						address={el?.address}
						onClick={() => handleWallboxClick(el)}
						deviceImage={wallboxIcon}
						tags={el.tags}
					/>
				))}
			</Stack>
			{/* <BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				mt: { md: 10, xs: 3 },

			}}
			>
				{t('your_hubs').toUpperCase()}
			</BodyText>
			<Stack direction="row" useFlexGap flexWrap="wrap" spacing={3} columnGap={3} sx={{ mt: 2.5 }}>
				{hub && hub.map((el) => (
					<DeviceCard
						key={el.userProductID}
						title={el.name}
						deviceName={`${el?.board?.productCode}${el?.board?.serialNumber}`}
						address={el?.address}
						onClick={() => handleDeviceClick(el)}
						deviceImage={analyzerIcon}
					/>
				))}
			</Stack> */}
			<DeviceSelectForReportModal
				open={showReportModal}
				handleClose={() => { setShowReportModal(false); }}
				devices={wallBox}
				handleClickShowReport={handleExportClick}
				reportLoading={reportLoading}
			/>
		</Box>
	);
};

export default DeviceListPage;
