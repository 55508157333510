import { BodyText, SizedBox, TitleText } from 'common/presentation/components';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

const StartSection = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const theme = useTheme();

	const handleStartClick = () => {
		navigate(AppLinks.FIND_YOUR_IOKEE_PURPOSE);
	};

	return (
		<Stack
			sx={{
				height: '80vh',
				pl: {
					md: Dimens.PAGE_PADDING,
					sm: Dimens.PAGE_PADDING_MOBILE,
					xs: Dimens.PAGE_PADDING_MOBILE,
				},
				pr: {
					md: Dimens.PAGE_PADDING,
					sm: Dimens.PAGE_PADDING_MOBILE,
					xs: Dimens.PAGE_PADDING_MOBILE,
				},
			}}
			alignItems="center"
			justifyContent={{ md: 'center', sm: 'flex-start', xs: 'flex-start' }}
		>
			<TitleText
				sx={{
					fontStyle: 'italic',
					alignSelf: { md: 'center', sm: 'flex-start', xs: 'flex-start' },
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
				dangerouslySetInnerHTML={{ __html: t('find_your_iokee_html').capitalizeFIrstDigit() }}
			/>
			<SizedBox height={1} />
			<BodyText sx={{
				fontStyle: 'italic',
				fontSize: {
					md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE,
				},
			}}
			>
				{t('meet_the_perfect_equipment').toUpperCase()}
			</BodyText>
			<SizedBox height={8} />
			<BodyText sx={{
				fontSize: {
					md: Dimens.BODY_TEXT_L,
					sm: Dimens.BODY_TEXT_L_MOBILE,
					xs: Dimens.BODY_TEXT_L_MOBILE,
				},
			}}
			>
				{t('click_here_to').toUpperCase()}
			</BodyText>
			<TitleText
				onClick={handleStartClick}
				sx={{
					cursor: 'pointer',
					fontSize: { md: Dimens.TILE_TEXT_XL, xs: Dimens.TITLE_TEXT_XL_MOBILE_FIND_IOKEE },
					fontWeight: Dimens.FONT_WEIGHT_BOLDEST,
					'&:hover': {
						color: theme.palette.primary.main,
						textShadow: '3px 3px 6px 0px rgba(77, 255, 255, 0.6)',
					},
				}}
			>
				{t('start').toUpperCase()}
			</TitleText>
		</Stack>
	);
};

export default StartSection;
