import { Either } from '@ajtec/either-typescript';
import { IProductStoreRepository } from '../repository/product-repository';
import { AxiosError } from 'axios';
import { IStoreProduct } from '../entities/product';

export class GetStoreProducts {
	repository: IProductStoreRepository;

	constructor(productRespository: IProductStoreRepository) {
		this.repository = productRespository;
	}

	call(categoryId?: string): Promise<Either<AxiosError, IStoreProduct[]>> {
		return this.repository.getAllProducts(categoryId);
	}
}
