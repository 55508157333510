export const getOrderStatusColors = (value: number) => {
	switch (value) {
	case 1:
		return {
			color: '#00A82D',
			bgColor: '#1aff57',
		};

	case 2:
		return {
			color: '#F79E1B',
			bgColor: '#fbcd8a',
		};
	case 3:
		return {
			color: '#CC5F5F',
			bgColor: '#e2a3a3',
		};
	case 4:
		return {
			color: '#FF0000',
			bgColor: '#FFE5CC',
		};
	default:
		return {
			color: 'grey',
			bgColor: 'transparent',
		};
	}
};
