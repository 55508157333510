import React from 'react';
import StepsView from './StepsView';

const FindYourIokeeSteps = () => (
	<main>
		<StepsView />
	</main>
);

export default FindYourIokeeSteps;
