import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type FormTabProps = {
	id: number
	name: string
}

type FormTabContextProps = {
	activeTab: number
	setActiveTab: (index: number) => void
	tabs: FormTabProps[]
	addTab: (name: string) => void
	isAddTabButtonDisabled: boolean
	setIsAddTabButtonDisabled: (value: boolean) => void
}

const FormTabsContext = createContext({} as FormTabContextProps);

export const FormTabProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const [activeTab, setActiveTab] = useState<number>(0);
	const [tabs, setTabs] = useState<FormTabProps[]>([{
		id: 1,
		name: t('main'),
	}]);
	const [isAddTabButtonDisabled, setIsAddTabButtonDisabled] = useState(true);

	// useEffect(() => {
	// 	setTabs([{
	// 		id: 1,
	// 		name: t('main'),
	// 	}]);
	// }, []);

	const addTab = (name: string) => {
		setTabs((prev) => [...prev, {
			id: 2,
			name,
		}]);
		setActiveTab(tabs.length);
	};

	const value = useMemo(() => ({
		activeTab,
		setActiveTab,
		addTab,
		tabs,
		isAddTabButtonDisabled,
		setIsAddTabButtonDisabled,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [activeTab, tabs, isAddTabButtonDisabled]);

	return (
		<FormTabsContext.Provider value={value}>
			{children}
		</FormTabsContext.Provider>
	);
};

export const useFormTabs = () => useContext(FormTabsContext);
