import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import CheckboxCard from '../components/CheckboxCard';
import {
	BusinessIcon,
	PrivateIcon,
} from 'assets/find-your-iokee-icons';
import { ArrowForward } from '@mui/icons-material';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { IQuestion } from '../../domain/question.model';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

interface Props {
	onContinueClick: () => void
	question?: IQuestion
}

const PurposeSection: React.FC<Props> = (props) => {
	const { onContinueClick, question } = props;
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const {
		getValue, handleValueChange, isAnyAnswerSelected,
	} = useFindYourIokee();

	const [isCompanySelected, setIsCompanySelected] = useState(false);

	const handleContinueClick = () => {
		if (isCompanySelected) {
			navigate(AppLinks.CONTACT_US);
		} else {
			onContinueClick();
		}
	};

	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 5 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
			>
				{question?.description?.toUpperCase()}
			</TitleText>
			<Stack direction="row" spacing={{ md: 4, xs: 2, sm: 2 }}>
				{question?.answers?.map((el, index) => (
					<CheckboxCard
						key={el.id}
						onClick={() => {
							handleValueChange(question.id, el.id);
							if (index === 0) {
								setIsCompanySelected(true);
							} else {
								setIsCompanySelected(false);
							}
						}}
						selected={getValue(question.id, el.id)}
						title={el?.text?.toUpperCase()}
						icon={index === 0 ? <BusinessIcon /> : <PrivateIcon />}
					/>
				))}
			</Stack>
			<DefaultButton disabled={!isAnyAnswerSelected(question?.id)} variant="contained" endIcon={<ArrowForward />} onClick={handleContinueClick}>
				{t('continue')}
			</DefaultButton>
		</Stack>
	);
};

export default PurposeSection;
