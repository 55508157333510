import {
	Box,
	IconButton,
	Modal,
	Stack,
	Theme,
	ThemeProvider,
	createTheme,
	Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDesignTokens } from 'utils/theme.utils';
import image from './intersect.svg';
import { Close } from '@mui/icons-material';
import BodyText from '../body-text/BodyText';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import Dimens from 'assets/dimens';
import StyledSwitch from '../styled-switch/StyledSwitch';
import i18next from 'i18next';

interface CookiesDialogProps {
	show: boolean;
	setShow: () => void
}

const CookiesDialog: React.FC<CookiesDialogProps> = (props) => {
	const { show, setShow } = props;
	const { t } = useTranslation('translations');
	const { language } = i18next;

	const handleConsentClick = () => {
		LocalStorageWrapper.set(LocalStorageKeys.COOKIES, true);
		setShow();
	};

	const [theme, setTheme] = useState<Theme>();

	useEffect(() => {
		getDesignTokens('main').then((th) => {
			setTheme(createTheme(th));
		});
	}, []);

	const handleCookiesClick = () => {
		if (language === 'pt') {
			window.open('https://iokee.io/cookie_pt.html', '_blank');
		} else {
			window.open('https://iokee.io/cookie_en.html', '_blank');
		}
	};

	if (!theme) return <div />;

	return (
		<ThemeProvider theme={theme}>
			<Modal
				open={show}
				onClose={setShow}
				disableAutoFocus
			>
				<Box
					sx={{
						backgroundImage: {
							lg: `url(${image})`,
							xl: `url(${image})`,
							md: `url(${image})`,
							xs: 'none',
							sm: 'none',
						},
						backgroundColor: {
							xs: theme.palette.primary.main,
							sm: theme.palette.primary.main,
							md: 'transparent',
							lg: 'transparent',
							xl: 'transparent',
						},
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						backgroundSize: '100%',
						alignSelf: 'center',
						height: { xs: '85%', md: '688px' },
						width: { xs: '90%', md: '688px' },
						margin: 'auto',
						border: 'none',
						mt: {
							sm: 4, xs: 4, md: 0, xl: 0, lg: 0,
						},
						borderColor: {
							xs: theme.palette.textMain.main,
							sm: theme.palette.textMain.main,
							md: 'transparent',
							lg: 'transparent',
							xl: 'transparent',
						},
						borderWidth: 2,
						borderStyle: 'solid',
						borderTopLeftRadius: {
							xs: Dimens.CARD_BORDER_RADIUS,
							sm: Dimens.CARD_BORDER_RADIUS,
							md: 0,
						},
						borderBottomRightRadius: {
							xs: Dimens.CARD_BORDER_RADIUS,
							sm: Dimens.CARD_BORDER_RADIUS,
							md: 0,
						},
					}}
				>
					<Stack
						alignItems={{
							md: 'center',
							xl: 'center',
							lg: 'center',
							sm: 'flex-end',
							xs: 'flex-end',
						}}
						sx={{ pt: 2 }}
					>
						<IconButton onClick={setShow}>
							<Close />
						</IconButton>
					</Stack>
					<Stack
						alignItems="center"
						justifyContent="center"
						sx={{ height: '100%' }}
						spacing={{
							md: 2, xl: 2, lg: 2, xs: 4, sm: 4,
						}}
					>
						<BodyText
							sx={{
								width: { xs: '60%', md: '420px' },
								textAlign: 'center',
							}}
						>
							{t('cookie_dialog_info')}
						</BodyText>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								border: `1px solid ${theme.palette.textMain.main}`,
								padding: '5px 12px 5px 12px',
								borderRadius: '10px',
								width: { md: '360px', xs: '300px' },
							}}
						>
							<Box>
								<BodyText
									sx={{
										fontSize: Dimens.BODY_TEXT_S,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
										color: theme.palette.textMain.main,
									}}
								>
									{t('service_management')}
								</BodyText>
								<BodyText
									sx={{
										fontSize: Dimens.BUTTON_TEXT_MOBILE,
										fontWeight: Dimens.FONT_WEIGHT_LIGHT,
										color: theme.palette.textMain.main,
									}}
								>
									{t('audience_measurment')}
								</BodyText>
							</Box>
							<StyledSwitch />
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{
								border: `1px solid ${theme.palette.textMain.main}`,
								padding: '5px 12px 5px 12px',
								borderRadius: '10px',
								width: { md: '360px', xs: '300px' },
							}}
						>
							<Box>
								<BodyText
									sx={{
										fontSize: Dimens.BODY_TEXT_S,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
										color: theme.palette.textMain.main,
									}}
								>
									{t('google_analytics')}
								</BodyText>
								<BodyText
									sx={{
										fontSize: Dimens.BUTTON_TEXT_MOBILE,
										fontWeight: Dimens.FONT_WEIGHT_LIGHT,
										color: theme.palette.textMain.main,
									}}
								>
									{t('this_service_not_install_cookie')}
								</BodyText>
							</Box>
							<StyledSwitch />
						</Stack>
						<Stack alignItems="center" justifyContent="center">
							<Button
								variant="outlined"
								color="inherit"
								onClick={handleConsentClick}
								sx={{
									borderRadius: 50,
									borderWidth: '2px',
									width: '100%',
									fontWeight: Dimens.FONT_WEIGHT_BOLD,
									'&:hover': {
										bgcolor: theme.palette.textMain.main,
										color: theme.palette.background.default,
										borderColor: theme.palette.textMain.main,
									},
								}}
							>
								{t('confirm')}
							</Button>
							<BodyText
								onClick={handleCookiesClick}
								sx={{
									fontSize: Dimens.BUTTON_TEXT_MOBILE,
									fontWeight: Dimens.FONT_WEIGHT_LIGHT,
									color: theme.palette.textMain.main,
									textDecoration: 'underline',
									cursor: 'pointer',
									mt: 1,
								}}
							>
								{t('cookie_policy')}
							</BodyText>
						</Stack>
					</Stack>
				</Box>
			</Modal>
		</ThemeProvider>
	);
};

export default CookiesDialog;
