import React from 'react';
import ContactForm from './ContactForm';
import { ContactProvider } from './provider/contact-provider';
import { CommunityForm } from 'common/presentation/components';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';

const ContactUs = () => (
	<main>
		<ContactProvider>
			<ContactForm />
			<CommunityProvier>
				<CommunityForm />
			</CommunityProvier>
		</ContactProvider>
	</main>
);

export default ContactUs;
