import React from 'react';
import SearchResult from './SearchResult';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { CommunityForm } from 'common/presentation/components';

const SearchPage = () => (
	<main>
		<CommunityProvier>
			<SearchResult />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default SearchPage;
