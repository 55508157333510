import { Box, Stack } from '@mui/material';
import React from 'react';
import BodyText from '../body-text/BodyText';
import CounterButton from '../counter-button/CounterButton';
import LinearImageCarousel from '../linear-image-carousel/LinearImageCarousel';
import Dimens from 'assets/dimens';
import TitleText from '../title-text/TitleText';
import DefaultButton from '../default-button/DefaultButton';
import { AddShoppingCart } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useCart from '../../providers/cart.provider';
import { IStoreProduct } from 'features/products/domain/entities/product';
import { formatPrice } from 'utils/price.utils';

interface Props {
	description?: string
	price?: number
	product: IStoreProduct | null
}

const ProductCarouselCard: React.FC<Props> = (props) => {
	const {
		product,
	} = props;
	const { t } = useTranslation('translations');
	const { addToCart } = useCart();
	const [count, setCount] = React.useState(1);

	const handleIncrement = () => {
		setCount((prevCount) => prevCount + 1);
	};

	const handleDecrement = () => {
		setCount((prevCount) => {
			if (prevCount === 1) return prevCount;
			return prevCount - 1;
		});
	};

	const handleAddToCartClick = () => {
		if (product) {
			addToCart({
				productId: product.id,
				name: product.name,
				price: product.price,
				originalPrice: product.price,
				storageFee: 1,
				freeStorageTime: 2,
				image: product.attachments.find((el) => el.isMain)?.attachment.url || '',
				description: product.description,
				qty: count,
			});
		}
	};

	return (
		<Stack
			direction={{ md: 'row', xs: 'column' }}
			justifyContent="center"
			alignItems="center"
			spacing={20}
			id="energy-efficiency-controller-phases"
		>
			<Box sx={{ width: { md: '40%', xs: '100%' } }}>
				<LinearImageCarousel images={product?.attachments.map((el) => el.attachment.url) || []} />
			</Box>
			<Stack sx={{ width: { md: '30%', xs: '100%' } }} alignItems="center">
				<BodyText
					sx={{
						fontSize: {
							md: Dimens.BODY_TEXT_ML,
							xs: Dimens.BODY_TEXT_ML_MOBILE,
						},
					}}
				>
					{product?.description}
				</BodyText>
				<Stack>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={2}
						sx={{
							mt: {
								md: Dimens.APP_BAR_MARGIN_BOTTOM,
								xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
							},
						}}
					>
						<Stack alignItems="center" spacing={{ md: 0, xs: 1 }} direction={{ md: 'column', xs: 'row' }}>
							<TitleText
								sx={{
									fontSize: {
										md: Dimens.TITEL_TEXT_M,
										xs: Dimens.TITLE_TEXT_M_MOBILE,
									},
									fontWeight: Dimens.FONT_WEIGHT_NORMAL,
								}}
							>
								{formatPrice(product?.price || 0)}
							</TitleText>
							<BodyText>
								c/ IVA
							</BodyText>
						</Stack>
						<CounterButton
							count={count}
							increment={handleIncrement}
							decrement={handleDecrement}
						/>
					</Stack>
					<DefaultButton
						variant="contained"
						fullWidth
						startIcon={<AddShoppingCart />}
						onClick={handleAddToCartClick}
						endIcon={null}
						sx={{
							mt: {
								md: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL,
								xs: Dimens.CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE,
							},
							height: Dimens.CART_BUTTON_HEIGHT,
							transition: 'all 0.5s',
							'&:hover': {
								transform: 'scale(1.125)',
								fontWeight: Dimens.FONT_WEIGHT_BOLD,
							},
						}}
					>
						{t('add_to_cart')}
					</DefaultButton>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ProductCarouselCard;
