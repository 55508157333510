import { Period } from 'common/domain/entities/period';
import { TFunction } from 'i18next';

export interface IEnergyConsumption {
	monthlyExpectation: number;
	billingStart: number;
	previousPeriod: Period;
	previousConsumption: number;
	previousAmount: number;
	currentPeriod: Period;
	currentConsumption: number;
	currentAmount: number;
	consumptionProjection: number;
	amountProjection: number;
}

export interface EnergyConsumptionParams {
	userProductId: number;
	sensorTypeId: number
}

export const DEVICE_SINGLE_PHASE = 1;

export const GET_DROPDOWN_OPTIONS = (t: TFunction) => [{ id: 1, name: t('general') }, { id: 2, name: t('partial') }];
