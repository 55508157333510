import {
	Badge,
	Box, Divider, IconButton, Stack,
	SvgIcon,
	useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BodyText from '../body-text/BodyText';
import Dimens from 'assets/dimens';
import useCart from '../../providers/cart.provider';
import { formatPrice } from 'utils/price.utils';
import DefaultButton from '../default-button/DefaultButton';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import { ShoppingCartOutlined } from '@mui/icons-material';
import AppLinks from 'assets/applinks.routes';
import { useLocation, useNavigate } from 'react-router-dom';

const CartSummaryPopup: FC = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const {
		cartProducts, getTotalPrice, getTotalItems, clearCart,
	} = useCart();

	const linkStyle = {
		textDecoration: 'none',
	};

	const handleCartClick = () => {
		navigate(AppLinks.CHECKOUT_PAGE);
	};

	const handleClearCartClick = () => {
		clearCart();
	};

	const isPopoverDisabled = location.pathname === AppLinks.CHECKOUT_PAGE
		|| location.pathname === AppLinks.FINALIZE_ORDER
		|| cartProducts.length === 0;

	return (
		<PopupState variant="popover" popupId="cart-summary">
			{(popupState) => (
				<div>
					<IconButton
						onClick={handleCartClick}
						style={{ ...linkStyle, marginRight: '10px' }}
						{...(isPopoverDisabled ? {} : bindHover(popupState))}
					>
						<Badge badgeContent={getTotalItems()} color="primary">
							<SvgIcon sx={{ color: theme.palette.textMain.main }}>
								<ShoppingCartOutlined />
							</SvgIcon>
						</Badge>
					</IconButton>
					<HoverPopover
						{...bindPopover(popupState)}
						slotProps={{
							paper: {
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 0,
									height: '600px',
									width: '400px',
									bgcolor: theme.palette.background.default,
									p: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE,
									'& .MuiAvatar-root': {
										width: 32,
										height: 32,
										ml: -0.5,
										mr: 1,
									},
									'&::before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: 0,
										left: 14,
										width: 10,
										height: 10,
										bgcolor: theme.palette.background.default,
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							},
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						disableRestoreFocus
					>
						<BodyText sx={{ fontWeight: Dimens.FONT_WEIGHT_BOLD }}>{t('items_in_cart').format(getTotalItems())}</BodyText>
						<Stack
							sx={{
								mt: 2,
								height: '65%',
								overflowY: 'scroll',
							}}
						>
							{cartProducts.map((el) => (
								<Box
									key={el.productId}
									sx={{
										'&:hover': {
											bgcolor: '#eeeedd',
										},
									}}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										sx={{ mb: 1, mt: 1 }}
										spacing={1}
									>
										<img src={el.image} alt={el.name} height="80px" width="80px" style={{ objectFit: 'contain', flex: 0.15 }} />
										<Box sx={{ flex: 0.7 }}>
											<BodyText>{el.name}</BodyText>
											<BodyText>
												{t('qty')}
												:
												{' '}
												{el.qty}
											</BodyText>
										</Box>
										<BodyText sx={{ flex: 0.35 }}>{formatPrice(el.price * el.qty)}</BodyText>
									</Stack>
									<Divider />
								</Box>
							))}
						</Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{ mt: 1 }}
						>
							<BodyText>
								{t('total')}
							</BodyText>
							<Box>
								<BodyText sx={{ fontWeight: Dimens.FONT_WEIGHT_BOLD }}>
									{formatPrice(getTotalPrice())}
								</BodyText>
								<BodyText
									sx={{ fontSize: Dimens.BUTTON_TEXT_MOBILE, color: theme.palette.grey[500] }}
								>
									{t('taxes_included')}
								</BodyText>
							</Box>
						</Stack>
						<DefaultButton
							variant="contained"
							size="small"
							sx={{ mt: 1 }}
							fullWidth
							onClick={handleCartClick}
						>
							{t('go_to_cart')}
						</DefaultButton>
						<DefaultButton
							size="small"
							sx={{ mt: 1 }}
							fullWidth
							onClick={handleClearCartClick}
						>
							{t('clear_cart')}
						</DefaultButton>
					</HoverPopover>
				</div>
			)}
		</PopupState>
	);
};

export default CartSummaryPopup;
