import Space from 'common/presentation/components/space/Space';
import BackofficeTextField from 'features/backoffice/common/presentation/input/BackofficeTextField';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BackofficeAddEditProductCategoriesTranslatedForm = () => {
	const { t } = useTranslation('translations');
	return (
		<Box sx={{ mt: 2 }}>
			<Grid container mt={4} spacing={2}>
				<Grid item md={5} xs={12}>
					<Card
						sx={{
							boxShadow: 'none',
							p: 4,
							gap: 2,
						}}
					>
						<BackofficeTextField
							name="name"
							// value={formValues.name}
							// error={!!formErrors.name}
							// helperText={formErrors.name}
							// disabled={loading}
							// onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('name')}
							placeholder={t('name')}
						/>
						<Space height={2} />
						<BackofficeTextField
							name="subtitle"
							// value={formValues.subtitle}
							// error={!!formErrors.subtitle}
							// helperText={formErrors.subtitle}
							// disabled={loading}
							// onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('subtitle')}
							placeholder={t('subtitle')}
						/>
						<Space height={2} />
						<BackofficeTextField
							name="description"
							// value={formValues.description}
							// error={!!formErrors.description}
							// helperText={formErrors.description}
							multiline
							minRows={4}
							// disabled={loading}
							// onChange={(e) => onChange(e.target.name, e.target.value)}
							label={t('description')}
							placeholder={t('description')}
						/>
						<Space height={2} />
						<LoadingButton variant="contained">
							{t('save')}
						</LoadingButton>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default BackofficeAddEditProductCategoriesTranslatedForm;
