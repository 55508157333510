/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
import { BodyText } from 'common/presentation/components';
import {
	Add, Delete, Edit,
} from '@mui/icons-material';
import {
	Box, Button, IconButton, Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useBackofficeQuestions } from '../../providers/question.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Dialog from 'common/presentation/components/dailog/Dailog';
import { formatDate } from 'utils/date/date.utils';

const BackofficeQuestionsPage = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const {
		getQuestions, questions, questionState, deleteQuestion,
	} = useBackofficeQuestions();

	const [selectedQuestions, setSelectedQuestions] = useState<DataTableValue | null>(null);
	const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

	useEffectCustom(() => {
		getQuestions();
	}, []);

	const handleAddClick = () => {
		navigate(AppLinks.BACKOFFICE_ADD_QUESTIONS);
	};
	const handleEditClick = () => navigate(
		AppLinks.BACKOFFICE_EDIT_QUESTIONS.formatMap({ questionId: selectedQuestions?.id }),
	);

	const handleDeleteClick = () => {
		deleteQuestion(selectedQuestions?.id);
	};

	const handleAddAnswersClick = (id: number) => {
		navigate(AppLinks.BACKOFFICE_ADD_ANSWERS.formatMap({ questionId: id }));
	};

	return (
		<Box>
			<Dialog
				title={t('alert')}
				description={t('confirm_delete_info')}
				open={confirmDeleteDialog}
				handleClose={() => setConfirmDeleteDialog(false)}
				primaryButtonText={t('confirm')}
				primaryButtonAction={handleDeleteClick}
				secondaryButtonText={t('cancel')}
				secondaryButtonAction={() => setConfirmDeleteDialog(false)}
			/>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('questions')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
				<IconButton onClick={handleAddClick}>
					<Add />
				</IconButton>
				{selectedQuestions && (
					<>
						<IconButton onClick={handleEditClick}>
							<Edit />
						</IconButton>
						<IconButton onClick={() => setConfirmDeleteDialog(true)}>
							<Delete />
						</IconButton>
					</>
				)}
			</Stack>
			<Box className="card" mt={6}>
				<DataTable
					value={questions}
					size="normal"
					selection={selectedQuestions}
					selectionMode="single"
					onSelectionChange={(e) => setSelectedQuestions(e.value)}
					rowClassName={(rowData) => {
						if (rowData.id === selectedQuestions?.id) return 'bg-cyan-100';
					}}
					loading={questionState.loading}
					dataKey="id"
					tableStyle={{ minWidth: '50rem' }}
				>
					<Column field="id" header="ID" align="center" />
					<Column
						field="description"
						header={t('description')}
						style={{ width: '30%' }}
						align="left"
					/>
					<Column
						field="stepOrder"
						header={t('step_order')}
						align="center"
					/>
					<Column
						field="createDate"
						header={t('created_on')}
						align="center"
						body={(options) => formatDate(options.createDate, 'DD-MM-yyyy')}
					/>
					<Column
						header={t('action')}
						align="right"
						body={(options) => (
							<Button
								variant="contained"
								startIcon={<Add />}
								sx={{ textTransform: 'none' }}
								onClick={() => handleAddAnswersClick(options.id)}
							>
								{t('answers')}
							</Button>
						)}
					/>
				</DataTable>
			</Box>
		</Box>
	);
};

export default BackofficeQuestionsPage;
