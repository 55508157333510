import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { IAttachment, IStoreProduct } from '../../domain/entities/product';
import { ProductStoreRepositoryImpl } from '../../data/repository/product-repository-impl';
import { GetStoreProductById } from '../../domain/usecases/get-product-by-id';
import { GetStoreProducts } from '../../domain/usecases/get-all-products';

type TProps = {
	loading: boolean
	getProducts: (categoryId: string) => void
	getProductById: (id: string) => void
	products: IStoreProduct[]
	productById: IStoreProduct | null
}

const StoreProductContext = createContext({} as TProps);

export const StoreProductProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([] as IStoreProduct[]);
	const [productById, setProductById] = useState<IStoreProduct | null>(null);

	const productRepository = new ProductStoreRepositoryImpl();

	const getProductById = async (id: string) => {
		setLoading(true);
		const prodByIdUsecase = new GetStoreProductById(productRepository);
		const prodByIdResult = await prodByIdUsecase.call(id);
		if (prodByIdResult.isRight()) {
			const mAttachments = [] as IAttachment[];
			const mainAttachment = prodByIdResult.value.attachments.find((el) => el.isMain);
			if (mainAttachment) {
				mAttachments.push(mainAttachment);
			}
			prodByIdResult.value.attachments.forEach((el) => {
				if (!el.isMain) {
					mAttachments.push(el);
				}
			});
			setProductById({ ...prodByIdResult.value, attachments: mAttachments });
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const getProducts = async (categoryId?: string) => {
		setLoading(true);
		const prodUseCase = new GetStoreProducts(productRepository);
		const prodResult = await prodUseCase.call(categoryId);
		if (prodResult.isRight()) {
			setProducts(prodResult.value);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const value = useMemo(() => ({
		productById,
		products,
		loading,
		getProductById,
		getProducts,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [productById,
		products,
		loading]);

	return (
		<StoreProductContext.Provider value={value}>{children}</StoreProductContext.Provider>
	);
};

export function useStoreProducts() {
	return useContext(StoreProductContext);
}
