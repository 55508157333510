import { TitleText } from 'common/presentation/components';
import {
	Box, Stack,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import InstallationSteps from '../components/InstallationSteps';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useStoreProducts } from '../provider/product-provider';
// import BannerQuizz from 'features/community/presentation/iokee-community/components/BannerQuizz';

const Setup = () => {
	const { t } = useTranslation('translations');
	const [activeIndex, setActiveIndex] = useState(0);
	const { productById } = useStoreProducts();
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

	useEffectCustom(() => {
		if (productById?.installationSteps && productById.installationSteps.length > 1) {
			intervalRef.current = setInterval(() => {
				setActiveIndex((prev) => {
					let mPrev = prev;
					if (mPrev !== productById.installationSteps.length) {
						mPrev += 1;
						return mPrev;
					}
					return 0;
				});
			}, 6000);
		}
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			intervalRef.current = null;
		};
	}, []);

	return (
		<Box
			sx={{
				height: {
					md: Dimens.ABOUT_IOKEE_SECTION,
				},
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
		>
			<TitleText sx={{
				fontSize: {
					md: Dimens.TITEL_TEXT_M,
					xs: Dimens.TITLE_TEXT_M_MOBILE,
				},
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
			}}
			>
				{t('installation').toUpperCase()}
			</TitleText>
			<TitleText sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL },
				fontWeight: Dimens.FONT_WEIGHT_LIGHT,
			}}
			>
				{t('install_and_setup').toUpperCase().format(productById?.installationSteps?.length)}
			</TitleText>
			<Stack rowGap={8}>
				{productById?.installationSteps.map((step, index) => (
					<InstallationSteps
						key={step.id}
						align={index === 1 ? 'flex-end' : undefined}
						title={step.header}
						description={step.description}
						count={index + 1}
						isHighlighted={activeIndex === index}
					/>
				))}
			</Stack>
			{/* <Box sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<BannerQuizz />
			</Box> */}
		</Box>
	);
};

export default Setup;
