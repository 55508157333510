import React from 'react';
import ProductSummary from './ProductSummary';
import { OrderProvider } from '../provider/order-provider';

const CheckoutPage = () => (
	<main>
		<OrderProvider>
			<ProductSummary />
		</OrderProvider>
	</main>

);

export default CheckoutPage;
