import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import { Box, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	title: string
	onClick: () => void
}

const ChoiceLabel: React.FC<Props> = (props) => {
	const { title, onClick } = props;
	const theme = useTheme();
	return (
		<Box
			component="span"
			sx={{
				borderRadius: Dimens.OPTION_LABEL_BORDER_RADIUS,
				bgcolor: theme.palette.textMain.main,
				p: Dimens.OPTION_LABEL_PADDING,
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_S,
				fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				color: theme.palette.textMain.contrastText,
			}}
			>
				{title.toUpperCase()}
			</BodyText>
		</Box>
	);
};

export default ChoiceLabel;
