/* eslint-disable consistent-return */
import { BodyText } from 'common/presentation/components';
import {
	Box,
	IconButton,
	Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import { useCategories } from '../../providers/product-categories.provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from 'utils/date/date.utils';
import { IProductCategory } from '../domain/entities/product-categories';
import Dialog from 'common/presentation/components/dailog/Dailog';
import Dimens from 'assets/dimens';

const BackofficeProductCategoriesPage = () => {
	const { t } = useTranslation('translations');
	const {
		getCategories,
		categories,
		deleteCategoryById,
		categoriesFetchState,
	} = useCategories();
	const navigate = useNavigate();
	const [selectedcategories, setSelectedCategories] = useState<DataTableValue | null>(null);
	const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

	useEffectCustom(() => {
		getCategories();
	}, []);

	const handleAddClick = () => {
		navigate(AppLinks.BACKOFFICE_ADD_PRODUCT_CATEGORIES);
	};

	const isVisibleBodyTemplate = (rowData: IProductCategory) => (rowData.isVisible
		? t('yes') : t('no'));

	const imageBodyTemplate = (category: IProductCategory) => <img src={category.attachment.url} alt={category.name} className="w-6rem shadow-2 border-round" />;

	const handleEditClick = () => navigate(
		AppLinks.BACKOFFICE_EDIT_PRODUCT_CATEGORIES.formatMap({ categoryId: selectedcategories?.id }),
	);

	const handleDeleteClick = () => {
		deleteCategoryById(selectedcategories?.id);
	};

	const descriptionBody = (category: IProductCategory) => (
		<Box>
			<BodyText sx={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '1',
				WebkitBoxOrient: 'vertical',
			}}
			>
				{category.subtitle}
			</BodyText>
			<BodyText sx={{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitLineClamp: '2',
				WebkitBoxOrient: 'vertical',
				fontSize: Dimens.BODY_TEXT_S,
			}}
			>
				{category.description}
			</BodyText>
		</Box>
	);

	return (
		<Box>
			<Dialog
				title={t('alert')}
				description={t('confirm_delete_info')}
				open={confirmDeleteDialog}
				handleClose={() => setConfirmDeleteDialog(false)}
				primaryButtonText={t('confirm')}
				primaryButtonAction={handleDeleteClick}
				secondaryButtonText={t('cancel')}
				secondaryButtonAction={() => setConfirmDeleteDialog(false)}
			/>
			<Stack direction="row" spacing={2}>
				<BodyText>
					{t('product_categories')}
				</BodyText>
				<Box sx={{ flexGrow: 1 }} />
				<IconButton onClick={handleAddClick}>
					<Add />
				</IconButton>
				{selectedcategories && (
					<>
						<IconButton onClick={handleEditClick}>
							<Edit />
						</IconButton>
						<IconButton onClick={() => setConfirmDeleteDialog(true)}>
							<Delete />
						</IconButton>
					</>
				)}
			</Stack>
			<Box className="card" mt={6}>
				<DataTable
					value={categories}
					size="normal"
					selection={selectedcategories}
					selectionMode="single"
					onSelectionChange={(e) => setSelectedCategories(e.value)}
					rowClassName={(rowData) => {
						if (rowData.id === selectedcategories?.id) return 'bg-cyan-100';
					}}
					loading={categoriesFetchState.loading}
					dataKey="id"
					tableStyle={{ minWidth: '50rem' }}
				>
					<Column field="id" header="ID" align="center" />
					<Column field="name" header={t('name')} align="center" />
					<Column field="" header={`${t('subtitle')}/${t('description')}`} style={{ width: '25%' }} body={descriptionBody} />
					<Column field="attachment.url" header={t('image')} align="center" body={imageBodyTemplate} />
					<Column field="isVisible" header={t('visible')} align="center" body={(options) => isVisibleBodyTemplate(options)} />
					<Column field="createDate" header={t('created_on')} align="center" body={(options) => formatDate(options.createDate, 'DD-MM-yyyy')} />
				</DataTable>
			</Box>
		</Box>
	);
};

export default BackofficeProductCategoriesPage;
