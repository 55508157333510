import { Either } from '@ajtec/either-typescript';
import { ILandingRepository } from '../repository/landing-repository';
import { AxiosError } from 'axios';
import { ICategories } from '../entities/categories';

export class GetCategories {
	repository: ILandingRepository;

	constructor(landingRepository: ILandingRepository) {
		this.repository = landingRepository;
	}

	call(isVisible: boolean): Promise<Either<AxiosError, ICategories[]>> {
		return this.repository.getCategories(isVisible);
	}
}
