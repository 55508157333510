/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useTranslation } from 'react-i18next';
import { IOrder, IOrderParams } from '../orders/entities/order';
import { OrderBackofficeApiImpl } from '../orders/data/order-api-impl';
import { ApiResponse } from 'common/data/entities/api-response';

type Props = {
	loading: boolean
	getOrders: (clear?: boolean) => void
	orders: ApiResponse<IOrder[]> | undefined
	changeStatus: (id: number, status: number) => void
	newOrdersCount: number
	getNewOrdersNotification: () => void
	currentPage: number
	pageSize: number
	setCurrentPage: (params: number) => void
	setPageSize: (params: number) => void
	params?: IOrderParams | null
	setParams: (params: IOrderParams | null) => void
	requestParams?: IOrderParams
}

const OrderContext = createContext({} as Props);

export const BackofficeOrderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation('translations');
	const { addDialog } = useFeedback();
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState<ApiResponse<IOrder[]>>();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [newOrdersCount, setNewOrdersCount] = useState<number>(0);
	const [params, setParams] = useState<IOrderParams | null>(null);
	const [requestParams, setRequestParams] = useState<IOrderParams>();

	const orderApiImpl = new OrderBackofficeApiImpl();

	const getOrders = async (clear?: boolean) => {
		setLoading(true);
		try {
			const getOrdersResult = await orderApiImpl.getOrders({
				Skip: (currentPage - 1) * pageSize,
				Take: pageSize,
				FullName: clear ? '' : params?.FullName,
				Email: clear ? '' : params?.Email,
				Status: clear ? '' : params?.Status,
			});
			setOrders(getOrdersResult.response);
			setRequestParams(getOrdersResult.params);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		} finally {
			setLoading(false);
		}
	};

	const getNewOrdersNotification = async () => {
		try {
			const getOrderNotification = await orderApiImpl.getNewOrdersNotification();
			setNewOrdersCount(getOrderNotification);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const changeStatus = async (id: number, status: number) => {
		setLoading(true);
		try {
			const changeStatusResult = await orderApiImpl.changeOrderStatus(id, status);
			if (changeStatusResult) {
				getOrders();
				getNewOrdersNotification();
			}
		} catch {
			addDialog({
				error: true,
				message: t('unable_to_change_status'),
				title: t('error'),
			});
		} finally {
			setLoading(false);
		}
	};

	const value = useMemo(() => ({
		loading,
		orders,
		getOrders,
		changeStatus,
		newOrdersCount,
		getNewOrdersNotification,
		currentPage,
		pageSize,
		setCurrentPage,
		setPageSize,
		params,
		requestParams,
		setParams,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading, orders, newOrdersCount, currentPage, pageSize, params, requestParams]);

	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export function useBackofficeOrders() {
	return useContext(OrderContext);
}
