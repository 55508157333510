import Dimens from 'assets/dimens';
import { DefaultButton, TitleText } from 'common/presentation/components';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import CheckboxCard from '../components/CheckboxCard';
import { MonophaseIcon, TriphaseIcon } from 'assets/find-your-iokee-icons';
import useFindYourIokee from '../provider/find-your-iokee.provider';
import { IQuestion } from '../../domain/question.model';

interface Props {
	onBackClick: () => void
	onContinueClick: () => void
	question?: IQuestion
}

const ElectricalSystem: React.FC<Props> = (props) => {
	const { onBackClick, onContinueClick, question } = props;
	const { t } = useTranslation('translations');
	const { getValue, handleValueChange, isAnyAnswerSelected } = useFindYourIokee();
	return (
		<Stack alignItems="center" spacing={{ md: 10, xs: 5 }}>
			<TitleText
				sx={{
					fontStyle: 'italic',
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
			>
				{question?.description?.toUpperCase()}
			</TitleText>
			<Stack direction="row" spacing={4}>
				{question?.answers?.map((el, index) => (
					<CheckboxCard
						key={el.id}
						selected={getValue(question.id, el.id)}
						onClick={() => handleValueChange(question.id, el.id)}
						title={el?.text?.toUpperCase()}
						icon={index === 0 ? <TriphaseIcon /> : <MonophaseIcon />}
					/>
				))}
			</Stack>
			<Stack direction="row" spacing={4}>
				<DefaultButton startIcon={<ArrowBack />} endIcon={null} onClick={onBackClick}>
					{t('back')}
				</DefaultButton>
				<DefaultButton disabled={!isAnyAnswerSelected(question?.id)} variant="contained" endIcon={<ArrowForward />} onClick={onContinueClick}>
					{t('continue')}
				</DefaultButton>
			</Stack>
		</Stack>
	);
};

export default ElectricalSystem;
