import Dimens from 'assets/dimens';
import { BodyText, DefaultButton, TitleText } from 'common/presentation/components';
import {
	Box,
	Skeleton,
	Stack,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCard from './components/product-card/ProductCard';
import { useLanding } from './provider/landing-provider';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

const ProductSection = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { categories, getCategories, loading } = useLanding();

	useEffectCustom(() => {
		getCategories();
	}, []);

	const handleStoreClick = () => {
		navigate(AppLinks.STORE);
	};

	return (
		<section>
			<Box sx={{
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" alignItems="flex-start">
					<Box>
						<TitleText
							size={Dimens.TITEL_TEXT_M}
							sx={{ fontWeight: Dimens.FONT_WEIGHT_BOLD }}
						>
							{t('meet_our_devices').toUpperCase()}
						</TitleText>
						<BodyText
							size={Dimens.BODY_TEXT_XL}
							sx={{ fontStyle: 'italic' }}
						>
							{t('learn_each_of_them').toUpperCase()}
						</BodyText>
					</Box>
					<DefaultButton onClick={handleStoreClick}>
						{t('go_to_store')}
					</DefaultButton>
				</Stack>
				<Stack
					direction={{ md: 'row', xs: 'column' }}
					spacing={2}
					justifyContent="space-between"
					sx={{
						mt: Dimens.CARD_PADDING_LARGE,
					}}
				>
					{loading && [1, 2, 3].map((el) => <Skeleton key={el} height={700} width="27%" />)}
					{
						categories.length !== 0 && categories.map((category, index) => index < 3 && (
							<ProductCard
								image={category.attachment?.url}
								title={category.name}
								id={category.id.toString()}
							/>
						))
					}
				</Stack>

			</Box>
		</section>
	);
};

export default ProductSection;
