import React from 'react';
import {
	Stack, Box,
	useTheme,
} from '@mui/material';
import { BodyText } from 'common/presentation/components';
import Dimens from 'assets/dimens';

interface DownloadAppButtonProps {
    appStoreLink: string;
    appStoreImage: string;
    downloadText: string;
    storeName: string;
}

const DownloadAppButton: React.FC<DownloadAppButtonProps> = (props) => {
	const {
		appStoreLink,
		appStoreImage,
		downloadText,
		storeName,
	} = props;
	const theme = useTheme();

	const handleButtonClick = () => {
		window.open(appStoreLink, '_blank');
	};

	return (

		<Stack
			direction="row"
			spacing={2}
			onClick={handleButtonClick}
			sx={{
				backgroundColor: theme.palette.background.paper,
				padding: 1,
				cursor: 'pointer',
				boxShadow: '0px 2px 12px 0px rgba(77, 255, 255, 0.2)',
			}}
			alignItems="center"
		>
			<img src={appStoreImage} alt={`${downloadText} ${storeName}`} />
			<Box>
				<BodyText
					size={Dimens.BODY_TEXT_S}
					sx={{
						mt: 1,
						fontSize: {
							md: Dimens.BODY_TEXT_S,
							xs: Dimens.BUTTON_TEXT_MOBILE,
						},
					}}
				>
					{downloadText}
				</BodyText>

				<BodyText sx={{
					fontWeight: 'bold',
					fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_S },
				}}
				>
					{storeName}
				</BodyText>
			</Box>
		</Stack>

	);
};

export default DownloadAppButton;
