import Dimens from 'assets/dimens';
import {
	Box, Stack, Theme, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { logoBlack, logoBlackMobile } from 'assets/icons';
import UserCard from '../user-card/UserCard';
import LogoutButton from '../logout-button/LogoutButton';
import useEffectCustom from '../../hooks/useEffectCustom';
import useAuth from 'features/client-login/presentation/provider/auth.provider';

const ClientAreaSidebar = () => {
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const { getAlerts } = useAuth();

	useEffectCustom(() => {
		getAlerts();
	}, []);

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING_MOBILE, xs: 0 },
			pr: { md: Dimens.PAGE_PADDING_MOBILE, xs: 0 },
			pt: { md: Dimens.FOOTER_PADDING_VERTICAL, xs: Dimens.PAGE_PADDING_MOBILE },
			pb: Dimens.CLIENT_AREA_PADDING_BOTTOM,
			width: { md: '25%', xs: '100%' },
		}}
		>
			<Stack
				direction="column"
				alignItems="flex-start"
				justifyContent="space-between"
				sx={{ height: { md: '88vh' } }}
			>
				{isMd ? <img src={logoBlack} alt="Logo" /> : <img src={logoBlackMobile} alt="logo" />}
				<UserCard />
				{isMd && <LogoutButton />}
			</Stack>
		</Box>
	);
};

export default ClientAreaSidebar;
