/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { IOrderRequestParams } from '../../domain/entities/order';
import { OrderApi } from './order-api';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';
import { storePost } from 'common/data/remote/store-requests';

export class OrderApiImpl implements OrderApi {
	async createOrder(params: IOrderRequestParams): Promise<ApiResponse<string>> {
		try {
			const resp = await storePost({
				url: Endpoints.ORDER,
				data: params,
			});
			const data = resp.data as ApiResponse<string>;
			return data;
		} catch (error: any) {
			throw error;
		}
	}
}
