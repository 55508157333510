import React from 'react';
import CardsSection from './CardsSection';
import HeroSection from './HeroSection';
import { CommunityForm } from 'common/presentation/components';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';
import { Box } from '@mui/material';
import { lineimage } from 'assets/images/user-stories';

const MobileAppPage = () => (

	<main>
		<CommunityProvier>
			<Box sx={{
				backgroundImage: {
					xs: 'none',
					sm: 'none',
					md: `url(${lineimage})`,
				},
				backgroundSize: '65% 60% ',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundPositionY: '40%',
			}}
			>
				<HeroSection />
				<CardsSection />
				<CommunityForm />
			</Box>
		</CommunityProvier>
	</main>

);
export default MobileAppPage;
