/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { storeGet, storePatch } from 'common/data/remote/store-requests';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';
import { IOrder, IOrderParams } from '../entities/order';

export class OrderBackofficeApiImpl {
	async getOrders(params: IOrderParams): Promise<{ response: ApiResponse<IOrder[]>, params: IOrderParams }> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_ORDERS,
				params,
			});
			const data = { response: resp.data, params: resp.config.params } as { response: ApiResponse<IOrder[]>, params: IOrderParams };
			return data;
		} catch (error) {
			throw error;
		}
	}

	async changeOrderStatus(id: number, status: number): Promise<number> {
		try {
			const resp = await storePatch({
				url: Endpoints.STORE_ORDER_STATUS.format(id, status),
			});
			const data = resp.data as ApiResponse<IOrder[]>;
			return status;
		} catch (error) {
			throw error;
		}
	}

	async getNewOrdersNotification(): Promise<number> {
		try {
			const resp = await storeGet({
				url: Endpoints.STORE_ORDERS_NOTIFICATION,
			});
			const data = resp.data as ApiResponse<number>;
			return data.data;
		} catch (error) {
			throw error;
		}
	}
}
