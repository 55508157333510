/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { FindYourIokeeReq, IFindYourIokeeReq, IFoundProduct } from '../../domain/find-your-iokee';
import { FindYourIokeeApi } from '../../data/find-your-iokee-api-impl';
import { IQuestion } from '../../domain/question.model';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

type FindYourIokeeProps = {
	loading: boolean
	findYourIokeeData: FindYourIokeeReq
	handleSetFindYourIokeeData: (key: string, value: number|boolean|string) => void
	findYourIokeeReq: IFindYourIokeeReq[]
	setFindYourIokeeReq: (params: IFindYourIokeeReq[]) => void
	questions: IQuestion[]
	getQuestions: () => void
	getValue: (questionId: number, answerId: number) => boolean
	handleValueChange: (questionId: number, answerId: number) => void
	isAnyAnswerSelected: (questionId?: number) => boolean
	updateDevicesQuantity: (questionId: number, answerId: number, quantity: number) => void
	findMyIokee: () => void
	foundProducts: IFoundProduct[]
}

const FindYourIokeeContext = createContext({} as FindYourIokeeProps);

export const FindYourIokeeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { addDialog } = useFeedback();
	const navigate = useNavigate();
	const [loading] = useState(false);
	const [findYourIokeeData, setFindYourIokeeData] = useState({} as FindYourIokeeReq);
	const [findYourIokeeReq, setFindYourIokeeReq] = useState<IFindYourIokeeReq[]>([]);
	const [questions, setQuestions] = useState([] as IQuestion[]);

	const [foundProducts, setFoundProducts] = useState([] as IFoundProduct[]);

	const QuestionApi = new FindYourIokeeApi();

	const handleSetFindYourIokeeData = (key: string, value: number|boolean|string) => {
		setFindYourIokeeData({ ...findYourIokeeData, [key]: value });
	};

	const getQuestions = async () => {
		try {
			const resp = await QuestionApi.getQuestions();
			setQuestions(resp);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const getValue = (questionId: number, answerId: number): boolean => {
		const foundQuestion = questions.find((el) => el.id === questionId);
		if (foundQuestion) {
			const foundAnswer = foundQuestion.answers.find((answer) => answer.id === answerId);
			return foundAnswer ? foundAnswer.selected ?? false : false;
		}
		return false;
	};

	const handleValueChange = (questionId: number, answerId: number) => {
		const mQuestions = [...questions];
		const foundQuestion = mQuestions.find((el) => el.id === questionId);
		if (foundQuestion) {
			foundQuestion.answers = foundQuestion.answers.map((answer) => ({
				...answer,
				selected: answer.id === answerId,
			}));
			setQuestions(mQuestions);
		}
	};

	const isAnyAnswerSelected = (questionId?: number): boolean => {
		if (!questionId) return false;
		const foundQuestion = questions.find((el) => el.id === questionId);
		if (foundQuestion) {
			return foundQuestion.answers.some((answer) => answer.selected === true);
		}
		return false;
	};

	const updateDevicesQuantity = (questionId: number, answerId: number, quantity: number) => {
		const mQuestions = [...questions];
		const question = mQuestions.find((q) => q.id === questionId);
		if (question) {
			const answer = question.answers.find((a) => a.id === answerId);
			if (answer) {
				answer.devicesQuantity = quantity;
				answer.selected = true;
				setQuestions(mQuestions);
			}
		}
	};
	const getSelectedAnswers = () => {
		const selectedAnswers = questions
			.flatMap((question) => question.answers
				.filter((answer) => answer.selected)
				.map((answer) => ({
					answerId: answer.id,
					devicesQuantity: answer.devicesQuantity || 0,
				})));
		return { answers: selectedAnswers };
	};

	const findMyIokee = async () => {
		const answers = getSelectedAnswers();
		try {
			const resp = await QuestionApi.findYourIokee(answers);
			setFoundProducts(resp);
			navigate(AppLinks.FIND_YOUR_IOKEE_FOUND_DEVICES);
		} catch (error: any) {
			addDialog({
				title: error.title,
				message: error.message,
				error: true,
			});
		}
	};

	const value = useMemo(() => ({
		loading,
		findYourIokeeData,
		handleSetFindYourIokeeData,
		questions,
		getQuestions,
		findYourIokeeReq,
		setFindYourIokeeReq,
		getValue,
		handleValueChange,
		isAnyAnswerSelected,
		updateDevicesQuantity,
		findMyIokee,
		foundProducts,
	}), [loading, findYourIokeeData, questions, findYourIokeeReq, foundProducts]);

	return <FindYourIokeeContext.Provider value={value}>{children}</FindYourIokeeContext.Provider>;
};

export default function useFindYourIokee() {
	return useContext(FindYourIokeeContext);
}
