import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import type {} from '@mui/lab/themeAugmentation';
import {
	Box,
	CssBaseline,
	Theme,
	ThemeProvider,
	createTheme,
} from '@mui/material';
import { getDesignTokens } from './utils/theme.utils';
import ErrorDialog from './common/presentation/components/error-dailog/ErrorDailog';
import Toast from './common/presentation/components/toast/Toast';

const ClientAreaMain = () => {
	const [theme, setTheme] = useState<Theme>();
	// const navigate = useNavigate();
	useEffect(() => {
		getDesignTokens('client').then((th) => {
			setTheme(createTheme(th));
		});
		// window.addEventListener('popstate', () => {
		// 	navigate(1);
		// });
	}, []);
	if (!theme) return <div />;
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<ErrorDialog />
			<Toast />
			<Box>
				<Outlet />
			</Box>
		</ThemeProvider>
	);
};

export default ClientAreaMain;
