import { LoginCurve } from 'assets/icons';
import {
	Box, Stack, SvgIcon, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import BodyText from '../body-text/BodyText';
import useAuth from 'features/client-login/presentation/provider/auth.provider';
import { useTranslation } from 'react-i18next';

const LogoutButton = () => {
	const { logoutUser } = useAuth();
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Box
			sx={{
				width: '100%',
				height: '80px',
				pt: '15px',
				pb: '15px',
				pl: '22px',
				pr: '22px',
				borderRadius: '25px',
				border: `2px solid ${theme.palette.background.paper}`,
				cursor: 'pointer',
			}}
			onClick={logoutUser}
		>
			<Stack direction="row" alignItems="center">
				<Box sx={{
					height: '50px', width: '50px', bgcolor: theme.palette.error.main, borderRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
				>
					<SvgIcon>
						<LoginCurve />
					</SvgIcon>
				</Box>
				<BodyText sx={{
					fontSize: Dimens.BODY_TEXT_M,
					ml: 1.5,
					color: theme.palette.error.main,
				}}
				>
					{t('logout')}
				</BodyText>
			</Stack>
		</Box>
	);
};

export default LogoutButton;
