import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	dateColor?: string;
	tagColor?: string;
	title: string;
	date: string;
}

const CategoryItem: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		dateColor,
		title,
		date,
		tagColor,
	} = props;
	return (
		<Stack component="div" direction="row" alignItems="center" sx={{ mb: 3 }}>
			<BodyText
				component="p"
				// size={Dimens.BODY_TEXT_S}
				className="date-item"
				sx={{
					color: tagColor ?? theme.palette.primary.main,
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
					transition: 'font-size 0.5s ease',
				}}
			>
				{title.toUpperCase()}
			</BodyText>
			<BodyText
				component="p"
				// size={Dimens.BODY_TEXT_S}
				className="date-item"
				sx={{
					color: dateColor ?? theme.palette.background.default,
					transition: 'font-size 0.5s ease',
				}}
			>
				{' '}
				|
				{' '}
				{date}
			</BodyText>
		</Stack>
	);
};

export default CategoryItem;
