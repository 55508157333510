/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { LanguageProvider } from 'common/presentation/providers/language.provider';
import './app.css';
import AppRouter from './router';
import {} from './utils/extensions/string.extensions';
import { AuthProvider } from './features/client-login/presentation/provider/auth.provider';
import { FeedbackProvider } from './common/presentation/providers/feedback.provider';
import { CartProvider } from './common/presentation/providers/cart.provider';
import LocalStorageWrapper, { LocalStorageKeys } from './utils/storage.utils';
import CookiesDialog from './common/presentation/components/cookies-dialog/CookiesDialog';
import { ThemeProvider } from '@mui/system';
import { getDesignTokens } from './utils/theme.utils';
import { Theme, createTheme } from '@mui/material';
import { PrimeReactProvider } from 'primereact/api';
import { BackofficeAuthProvider } from './features/backoffice/features/auth/presentation/provider/auth.provider';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/lara-light-teal/theme.css';
import firebase from './services/firebase';

const App = () => {
	const cookie = LocalStorageWrapper.get(LocalStorageKeys.COOKIES);
	const [show, setShow] = React.useState(cookie ?? false);
	const [theme, setTheme] = useState<Theme>();

	useEffect(() => {
		getDesignTokens('main').then((th) => {
			setTheme(createTheme(th));
		});
	}, []);

	if (!theme) return <div />;

	const handleShow = () => {
		setShow(true);
	};

	return (
		<PrimeReactProvider>
			<ThemeProvider theme={theme}>
				<FeedbackProvider>
					<LanguageProvider>
						<AuthProvider>
							<BackofficeAuthProvider>
								<CookiesDialog show={!show} setShow={handleShow} />
								<CartProvider>
									<AppRouter />
								</CartProvider>
							</BackofficeAuthProvider>
						</AuthProvider>
					</LanguageProvider>
				</FeedbackProvider>
			</ThemeProvider>
		</PrimeReactProvider>
	);
};

export default App;
