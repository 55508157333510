import { Switch, SwitchProps, styled } from '@mui/material';
import React from 'react';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 51,
	height: 22,
	padding: 1,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 1,
		transform: 'translateX(1px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(30px)',
			'& .MuiSwitch-thumb:before': {
				background: 'linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 50%)',
				borderRadius: '20px',
			},
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.textMain.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		width: 14,
		height: 14,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 1,
			top: 2,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			background: 'linear-gradient(90deg, rgba(0,0,48,1) 50%, rgba(0,0,48,1) 50%)',
			borderRadius: '20px',
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.background.default,
		borderRadius: 31 / 2,
		border: `1px solid ${theme.palette.textMain.main}`,
	},
}));

const StyledSwitch: React.FC<SwitchProps> = (props) => (
	<MaterialUISwitch {...props} />
);

export default StyledSwitch;
